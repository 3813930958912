<div class="app-register container w-100">
  <div class="row align-items-center">
    <div class="col-md-7 col-sm-12">
      <div class="mb-4 body--big--darkgrey">
        {{ "register.already_have_an_account" | translate }}
        <a class="ms-2 a-link--big body--big-b--primary" (click)="goToLogin()">
          {{ "register.go_to_login_link" | translate }}
        </a>
      </div>
      <app-custom-panel [noMargin]="true">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="title--big--dark mb-3">
            {{ "register.title" | translate }}
          </div>
          <div class="body--big--darkgrey mb-4">
            {{ "register.subtitle" | translate }}
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="mb-3">
                <label for="firstname" class="label--darkgrey--85">{{
                  "field.firstname.label" | translate
                }}</label>
                <input
                  type="text"
                  class="a-input-form-group__input"
                  id="firstname"
                  name="firstname"
                  placeholder="{{ 'field.firstname.placeholder' | translate }}"
                  formControlName="firstname"
                  required
                />
                <ng-container *ngIf="f.firstname.errors && f.firstname.touched">
                  <div
                    *ngIf="f.firstname.errors.required"
                    class="a-input--error"
                  >
                    {{ "field.firstname.error.invalid" | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="mb-3">
                <label for="lastname" class="label--darkgrey--85">{{
                  "field.lastname.label" | translate
                }}</label>
                <input
                  type="text"
                  class="a-input-form-group__input"
                  id="lastname"
                  name="lastname"
                  placeholder="{{ 'field.lastname.placeholder' | translate }}"
                  formControlName="lastname"
                  required
                />
                <ng-container *ngIf="f.lastname.errors && f.lastname.touched">
                  <div
                    *ngIf="f.lastname.errors.required"
                    class="a-input--error"
                  >
                    {{ "field.lastname.error.invalid" | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="email" class="label--darkgrey--85">{{
              "field.email.label" | translate
            }}</label>
            <input
              type="text"
              class="a-input-form-group__input"
              id="email"
              name="email"
              placeholder="{{ 'field.email.placeholder' | translate }}"
              formControlName="email"
              required
            />
            <ng-container *ngIf="f.email.errors && f.email.touched">
              <div *ngIf="f.email.errors.required" class="a-input--error">
                {{ "field.email.error.invalid" | translate }}
              </div>
            </ng-container>
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="mb-3">
                <label for="password" class="label--darkgrey--85">{{
                  "field.password.label" | translate
                }}</label>
                <div class="m-input-password">
                  <input
                    [type]="showPassword ? 'text' : 'password'"
                    formControlName="password"
                    class="m-input-password__input"
                    id="password"
                    name="password"
                    placeholder="{{ 'field.password.placeholder' | translate }}"
                    required
                  />
                  <button
                    class="m-input-password__icon"
                    type="button"
                    (click)="toggleShowPassword()"
                  >
                    <fa-icon
                      class="fa-izi"
                      [icon]="showPassword ? faEyeSlash : faEye"
                    ></fa-icon>
                  </button>
                </div>

                <ng-container *ngIf="f.password.errors && f.password.touched">
                  <div
                    *ngIf="f.password.errors.required"
                    class="a-input--error"
                  >
                    {{ "field.password.error.invalid" | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="mb-3">
                <label for="confirmPassword" class="label--darkgrey--85">{{
                  "field.confirm_password.label" | translate
                }}</label>
                <div class="m-input-password">
                  <input
                    [type]="showConfirmPassword ? 'text' : 'password'"
                    class="m-input-password__input"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="{{
                      'field.confirm_password.placeholder' | translate
                    }}"
                    formControlName="confirmPassword"
                    required
                  />
                  <button
                    class="m-input-password__icon"
                    type="button"
                    (click)="toggleShowConfirmPassword()"
                  >
                    <fa-icon
                      class="fa-izi m-input-password__icon-color"
                      [icon]="showConfirmPassword ? faEyeSlash : faEye"
                    ></fa-icon>
                  </button>
                </div>
                <ng-container
                  *ngIf="f.confirmPassword.errors && f.confirmPassword.touched"
                >
                  <div
                    *ngIf="f.confirmPassword.errors.required"
                    class="a-input--error"
                  >
                    {{ "field.confirm_password.error.invalid" | translate }}
                  </div>
                  <div
                    *ngIf="f.confirmPassword.errors.mustMatch"
                    class="a-input--error"
                  >
                    {{ "field.confirm_password.error.must_match" | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="body--small--darkgrey mt-2 mb-4">
            {{ "register.accept_terms_and_conditions" | translate }}
            <a
              class="ms-1 body--small-m--darkgrey-underline a-link"
              (click)="showTermsAndConditions(termsAndConditionsModal)"
            >
              {{ "register.terms_and_conditions_link" | translate }}
            </a>
          </div>

          <div class="pt-3">
            <button
              type="submit"
              class="a-izi-btn--medium a-izi-btn--medium--primary"
              [disabled]="!registerForm.valid"
            >
              {{ "register.create_account" | translate }}
            </button>
          </div>
        </form>
      </app-custom-panel>
    </div>
    <div class="col-md-5 col-sm-12">
      <img src="assets/images/reel1.svg" alt="" />
    </div>
  </div>

  <ng-template #termsAndConditionsModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ "register.terms_and_conditions_link" | translate }}
      </h4>
    </div>
    <div class="modal-body">
      {{ "register.terms_and_conditions" | translate }}
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.close()"
      >
        {{ "common.close" | translate }}
      </button>
    </div>
  </ng-template>
</div>
