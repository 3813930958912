import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Observable } from 'rxjs';
import { IUser } from '../../core/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IOrder } from '../../core/models/order.model';
import { CityService } from '../../services/city.service';
import { IInvoice } from '../../core/models/invoice.model';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import { NavigatorService } from '../../services/navigator.service';
import { Card } from '../../core/models/card.model';
import { PaymentService } from '../../services/payment.service';
import { ActivatedRoute } from '@angular/router';
import { IDelivery } from '../../core/models/delivery.model';
import CryptoUtil from '../../utils/crypto.util';
import StorageUtil from '../../utils/storage.util';
import { IPayment } from '../../core/models/payment.model';
import { UPDATE_USER_CARDS } from '../../reducers/user.reducer';
import { UserService } from '../../services/user.service';
import { ToastService } from '../../services/toast.service';
import { LoaderService } from '../../services/loader.service';
import { NavigatorStep } from '../../states/navigator.state';

import { NGXLogger } from 'ngx-logger';

import DeviceUtil from '../../utils/device.util';
import { environment } from '../../../environments/environment';
import DataUtil from '../../utils/data.util';
import { PaymentCardComponent } from './payment-card/payment-card.component';
import { TranslateService } from '@ngx-translate/core';

declare let Cardinal: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  user$: Observable<IUser>;

  order$: Observable<IOrder>;

  selectedPaymentMethod: string;

  invoice$: Observable<IInvoice>;

  card: Card;

  delivery$: Observable<IDelivery>;

  paymentMethodOptions: Array<string>;

  useNewCard: boolean;

  payment$: Observable<IPayment>;

  taxpayerId: string;

  jwt: string;

  qrData: string;
  qrWaiting: boolean;

  @ViewChild(PaymentCardComponent) cardComponent: PaymentCardComponent;

  constructor(private route: ActivatedRoute,
              private store: Store<AppState>,
              private modalService: NgbModal,
              private cityService: CityService,
              private navigator: NavigatorService,
              private paymentService: PaymentService,
              private toastService: ToastService,
              private translateService: TranslateService,
              private userService: UserService,
              private loader: LoaderService,
              private logger: NGXLogger
  ) {
    this.user$ = this.store.select(state => state.user);
    this.order$ = this.store.select(state => state.order);
    this.invoice$ = this.store.select(state => state.invoice);
    this.delivery$ = this.store.select(state => state.delivery);
    this.payment$ = this.store.select(state => state.payment);

    this.card = new Card();
  }

  async ngOnInit(): Promise<void> {
    this.paymentMethodOptions = [];
    this.payment$.pipe(take(1)).subscribe((data: IPayment) => this.updateTaxpayerId(data));
  }

  updatePaymentMethod(event: string): void {
    this.selectedPaymentMethod = event;
    if(this.selectedPaymentMethod==="qr"){
      this.getPaymentQr();
    }
  }

  async updateTaxpayerId(payment: any): Promise<void> {
    this.taxpayerId = payment.taxpayer.id;
    await this.getCardList();
  }

  async getCardList(): Promise<void> {
    const { userId, token } = StorageUtil.getStoredParams();
    if (userId && token) {
      this.loader.open();
      const cards = await this.userService.getCards(userId, token, this.taxpayerId);
      this.store.dispatch({
        type: UPDATE_USER_CARDS,
        payload: {
          cards
        }
      });
    }
  }

  async onExecutePaymentEvent(): Promise<void> {
    const isValid = this.validatePaymentData();
    if (isValid) {
      await this.executePayment();
    } else {
      this.cardComponent.validateCardForm();
    }
  }

  validatePaymentData(): boolean {
    let order: IOrder;
    this.order$.pipe(take(1)).subscribe((data: IOrder) => { order = data; });
    console.log(order);
    if(DataUtil.isEmpty(order.currency)){
      this.translateService.get('field').subscribe((value)=>{
        this.toastService.show(value.currency.error.required);
      });
    }
    else if(DataUtil.isEmpty(order.total)){
      this.translateService.get('field').subscribe((value)=>{
        this.toastService.show(value.total.error.required);
      });
    }
    return !(
      DataUtil.isEmpty(order.currency) ||
      DataUtil.isEmpty(order.total) ||
      DataUtil.isEmpty(this.card.cardNumber) ||
      DataUtil.isEmpty(this.card.cardType) ||
      DataUtil.isEmpty(this.card.ccv) ||
      DataUtil.isEmpty(this.card.city) ||
      DataUtil.isEmpty(this.card.country) ||
      DataUtil.isEmpty(this.card.expirationDate) ||
      DataUtil.isEmpty(this.card.name)
    );
  }

  async executePayment(): Promise<void> {
    try {
      this.loader.open();
      const paymentData = await this.collectPaymentData();
      const paymentResult = await this.paymentService.executePayment(paymentData);
      if (paymentResult.status === 'PENDING_AUTHENTICATION') {
        await this.execute3DSecureValidation(paymentResult);
      } 
      else if (paymentResult.status === 'AUTHORIZED') {
        //this.navigator.go(NavigatorStep.paymentAccepted);
      }
      else{
        this.navigator.go(NavigatorStep.paymentFailed);
        this.loader.close();
      }
    } catch (e) {
      this.logger.error(e);
      this.navigator.go(NavigatorStep.paymentFailed);
      this.loader.close();
    }
  }

  async execute3DSecureValidation(paymentResult: any): Promise<void> {
    this.loader.open();

    const paymentId = this.route.snapshot.paramMap.get('id');

    const paymentData = await this.collectPaymentData();

    this.jwt = paymentResult.token;

    setTimeout(() => {
      Cardinal.on('payments.setupComplete', (setupCompleteData) => {
        this.logger.debug(setupCompleteData);
      });
    }, 0);

    setTimeout(() => {
      Cardinal.on('payments.validated', async (data, jwt) => {
        switch ((data.ActionCode || data.ErrorDescription).toUpperCase()) {
          case 'SUCCESS':
            await this.validateCurrentPayment(paymentId, paymentData, data);
            this.loader.close();
            break;
          case 'NOACTION':
            // Handle no actionable outcome
            break;
          case 'FAILURE':
            this.loader.close();
            break;
          case 'ERROR':
            this.loader.close();
            break;
        }
      });
    }, 0);

    await Cardinal.setup('init', {
      jwt: this.jwt,
      order: {
        Consumer: {
          Account: {
            AccountNumber: this.card.cardNumber
          }
        }
      }
    });

    await Cardinal.continue('cca',
      {
        AcsUrl: paymentResult.acsUrl,
        Payload: paymentResult.payload
      },
      {
        OrderDetails: {
          TransactionId: paymentResult.transactionId
        }
      });
  }

  async validateCurrentPayment(paymentId, paymentData, requestData): Promise<void> {
    try {
      this.loader.open();
      const paymentValidation = await this.paymentService.validatePayment(
        paymentId,
        { ...paymentData, payment: requestData.Payment }
      );
      if (paymentValidation.status === 'AUTHENTICATION_FAILED') {
        throw new Error(paymentValidation.errorInformation.message);
      } else {
        this.navigator.go(NavigatorStep.paymentAccepted);
      }
    } catch (e) {
      this.logger.error(e);
      this.navigator.go(NavigatorStep.paymentFailed);
    } finally {
      this.loader.close();
    }
  }

  async collectPaymentData(): Promise<any> {
    const { userId, token } = StorageUtil.getStoredParams();

    let delivery: IDelivery;
    this.delivery$.pipe(take(1)).subscribe((data) => ( delivery = data ));

    let invoice: IInvoice;
    this.invoice$.pipe(take(1)).subscribe((data) => ( invoice = data ));
    const invoiceData = _.reduce(invoice.fields, (obj, {keyAlt, value}) => ({
      ...obj,
      [keyAlt]: value
    }), {});

    const camposExtra = [];
      _.each(invoice.fields,(value,index)=>{
        if(value.valueRaw){
          camposExtra.push({
              id:value.valueRaw["id"] || 0,
              titulo: value.valueRaw["nombre"] || "",
              valor: value.value,
              visibleFactura: value.valueRaw["visibleFactura"] || true,
              visibleReporte:value.valueRaw["visibleReporte"] || true,
              visibleVista:value.valueRaw["visibleVista"] || true,
          })
        }
        if(value.key=="DOCUMENT_TYPE"){
          let type= _.find(value.options,(option)=>{
            return option.value==Number(value.value);
          })
          if(type){
            invoiceData["tipoDocumento"]={
              codigoClasificador:type.value,
              descripcion: type.text
            }
          }
        }
      })
    invoiceData["camposExtra"]=camposExtra;
    if(invoiceData["email"]){
      invoiceData["correoElectronico"]=invoiceData["email"];
      delete(invoiceData["email"]);
    }
    let order: IOrder;
    this.order$.pipe(take(1)).subscribe((data: IOrder) => { order = data; });
    
    let paymentId = this.route.snapshot.paramMap.get('id');
    

    let pagadorData = {
      data: CryptoUtil.encode(JSON.stringify(this.card)),
      deviceFingerprint: CryptoUtil.encode(JSON.stringify(
        {
          ... await DeviceUtil.getFingerPrint(),
          sessionId: order.sessionId
        }))
    }
    const result = {
      ventaData: invoiceData,
      uuid: paymentId,
      metodoPago: environment.idPaymentCard,
      pagadorData: pagadorData
    };

    if (environment.steps.addressEnabled) {
      _.set(result, ['delivery', 'address'], delivery.address);
      if (environment.steps.scheduleEnabled) {
        _.set(result, ['delivery', 'schedule'], delivery.schedule);
      }
    }

    return result;
  }

  updateCardData(card: Card): void {
    this.card = card;
  }

  async removeCard(card: Card): Promise<void> {
    try {
      const { userId, token } = StorageUtil.getStoredParams();
      if (userId && token) {
        this.loader.open();
        await this.userService.removeCard(userId, token, this.taxpayerId, card.id);
        await this.getCardList();
        this.loader.close();
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getPaymentQr():Promise<void>{
    try{
      if(!this.qrData && !this.qrWaiting){
        this.qrWaiting=true;
        let paymentId = this.route.snapshot.paramMap.get('id');
        let invoice: IInvoice;
        this.invoice$.pipe(take(1)).subscribe((data) => ( invoice = data ));
        const invoiceData = _.reduce(invoice.fields, (obj, {keyAlt, value}) => ({
          ...obj,
          [keyAlt]: value
        }), {});
  
        const camposExtra = [];
        _.each(invoice.fields,(value,index)=>{
          if(value.valueRaw){
            camposExtra.push({
                id:value.valueRaw["id"] || 0,
                titulo: value.valueRaw["nombre"] || "",
                valor: value.value,
                visibleFactura: value.valueRaw["visibleFactura"] || true,
                visibleReporte:value.valueRaw["visibleReporte"] || true,
                visibleVista:value.valueRaw["visibleVista"] || true,
            })
          }
          if(value.key=="DOCUMENT_TYPE"){
            let type= _.find(value.options,(option)=>{
              return option.value==Number(value.value);
            })
            if(type){
              invoiceData["tipoDocumento"]={
                codigoClasificador:type.value,
                descripcion: type.text
              }
            }
          }
        })
        
        invoiceData["camposExtra"]=camposExtra;

        if(invoiceData["email"]){
          invoiceData["correoElectronico"]=invoiceData["email"];
          delete(invoiceData["email"]);
        }
        let data  = {
          ventaData: invoiceData,
          uuid: paymentId,
          metodoPago: environment.idPaymentQr,
          pagadorData: {}
        }
        let dataResp = await this.paymentService.executePayment(data);
        this.qrData = dataResp.qr;
        this.qrWaiting=false;
      }
    }
    catch(error){
      console.log(error);
      this.qrData = null;
      this.qrWaiting=false;
    }
  }

  resetQrEvent():void{
    this.qrData = null;
    this.qrWaiting=false;
    if(this.selectedPaymentMethod === 'qr'){
      this.getPaymentQr();
    }
  }
}

