export interface ITaxpayer {
  id: number;
  logo: string;
  logoOrientation: string;
}

export class Taxpayer implements ITaxpayer {
  public id: number;
  public logo: string;
  public logoOrientation: string;

  constructor(user?: ITaxpayer ) {
    this.id = user && user.id || 0;
    this.logo = user && user.logo || '';
    this.logoOrientation = user && user.logoOrientation || 'landscape';
  }
}

export const initialUserState: ITaxpayer = new Taxpayer();
