export class Address {
  public alias: string;
  public id: string;
  public country: string;
  public city: string;
  public address: string;
  public latitude: number;
  public longitude: number;
  public save: boolean;
  public description: string;

  constructor(record?: Address) {
    this.id = record && record.id || '';
    this.alias = record && record.alias || '';
    this.country = record && record.country || '';
    this.city = record && record.city || '';
    this.address = record && record.address || '';
    this.latitude = record && record.latitude || 0;
    this.longitude = record && record.longitude || 0;
    this.save = record && record.save || true;
    this.description = record && record.description || '';
  }
}
