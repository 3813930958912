import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { HIDE_LOADER, SHOW_LOADER } from '../reducers/loader.reducer';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private store: Store<AppState>) {}

  open(): void {
    this.store.dispatch({
      type: SHOW_LOADER
    });
  }

  close(): void {
    this.store.dispatch({
      type: HIDE_LOADER
    });
  }
}
