// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  apiURL: 'https://api.staging.izisoluciones.com',
  apiNotificationURL: 'https://api.notificaciones.staging.izisoluciones.com/api/v1',
  googleApiKey: 'AIzaSyBHXaEdlsrxpGwGmP-14ylDP8fxDdeDgfQ', // googleApiKey: 'AIzaSyB33Y1l19QGjTjmKjfuBfr-pD_SGLtVELk',
  relatedBusiness: 'www.izisoluciones.com',
  iziWebPage: 'www.izisoluciones.com',
  termsAndConditions: 'https://izisoluciones.com/izi-privacy-policy.pdf',
  enableAutoLogin: true,
  songbirdURL:
    'https://songbirdstag.cardinalcommerce.com/cardinalcruise/v1/songbird.js',
  logLevel: NgxLoggerLevel.TRACE,
  steps: {
    addressEnabled: false,
    scheduleEnabled: false,
  },
  idPaymentQr: 3,
  idPaymentCard: 2
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
