import { initialNavigatorState, NavigatorState, NavigatorStep } from '../states/navigator.state';

export const GO_TO = 'GO_TO';
export const BACK = 'BACK';

export function navigatorReducer(state: NavigatorState = initialNavigatorState, action): any {
  switch (action.type) {
    case GO_TO:
      const lastStep = state.step !== NavigatorStep.empty ? state.step : null;

      if (lastStep !== null) {
        return {
          ...action.payload,
          steps: [
            ...state.steps,
            lastStep
          ]
        };  
      }
      
      return {
        ...action.payload,
        steps: [
          ...state.steps
        ]
      };
    case BACK:
      return {
        step: state.steps[state.steps.length - 1],
        steps: state.steps.slice(0, -1)
      };
    default:
      return state;
  }
}
