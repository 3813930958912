import { initialPaymentState, IPayment } from '../core/models/payment.model';

export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_PAYER = 'UPDATE_PAYER';
export const UPDATE_DELIVERY_ADDRESS = 'UPDATE_DELIVERY_ADDRESS';
export const UPDATE_TAXPAYER = 'UPDATE_TAXPAYER';

export function paymentReducer(state: IPayment = initialPaymentState, action): any {
  switch (action.type) {
    case UPDATE_INVOICE:
      return {
        ...state,
        invoice: action.payload.invoice
      };
    case UPDATE_PAYER:
      return {
        ...state,
        payer: action.payload.payer
      };
    case UPDATE_DELIVERY_ADDRESS:
      return {
        ...state,
        address: action.payload.address
      };
    case UPDATE_TAXPAYER:
      return {
        ...state,
        taxpayer: action.payload.taxpayer
      };
    default:
      return state;
  }
}
