import { Component } from '@angular/core';
import { NavigatorStep } from '../../../states/navigator.state';
import { NavigatorService } from '../../../services/navigator.service';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IOrder, Order } from '../../../core/models/order.model';
import { AppState } from '../../../app.state';
import * as _ from 'lodash';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent {
  order$: Observable<IOrder>;
  order: Order;

  constructor(
    private navigator: NavigatorService,
    private store: Store<AppState>,
    ) {
    this.order$ = this.store.select(state => state.order);
   }
   ngOnInit(): void {
    this.order$ = this.store.select(state => state.order);
    this.order$.subscribe((data) => this.updateOrder(data));
  }
  updateOrder(data: IOrder): void {
    this.order = _.cloneDeep(data);
  }
  goToRelatedBusiness(): void {
    window.open(environment.relatedBusiness, '_blank');
  }

  goToRegister(): void {
    this.navigator.go(NavigatorStep.register);
  }

  goToHome(): void {
    console.log(this.order);
    if(this.order.notificationUrl){
      let notifUrl = "";
      if (!this.order.notificationUrl.includes("?")) {
        notifUrl = this.order.notificationUrl + "/?";
      }
      else {
        notifUrl = this.order.notificationUrl + "&";
      }
      notifUrl += "order=" + this.order.config.order +
        "&result=success";
      window.open(notifUrl, '_blank');
      return;
    }
    window.open(environment.iziWebPage, '_blank');
  }
}

