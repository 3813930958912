import { initialInvoiceState, IInvoice, Invoice } from '../core/models/invoice.model';
import * as _ from 'lodash';
import { Field } from '../core/models/field.model';
import { DocTypeIdentity } from '../core/models/docTypeIdentity.model';

export const UPDATE_INVOICE_FIELDS = 'UPDATE_INVOICE_FIELDS';
export const ADD_INVOICE_FIELD = 'ADD_INVOICE_FIELD';
export const UPDATE_INVOICE_FIELD = 'UPDATE_INVOICE_FIELD';
export const ADD_DOCUMENT_TYPE = 'ADD_DOCUMENT_TYPE';
export const UPDATE_INVOICE_FIELD_IF_EMPTY = 'UPDATE_INVOICE_FIELD_IF_EMPTY';
export const REPLACE_INVOICE_FIELD = 'REPLACE_INVOICE_FIELD';

export function invoiceReducer(state: IInvoice = initialInvoiceState, action): any {
  switch (action.type) {
    case UPDATE_INVOICE_FIELDS:
      return {
        ...state,
        fields: [
          ...state.fields,
          ...action.payload.fields
        ]
      };
    case REPLACE_INVOICE_FIELD:
      return {
        ...state,
        fields: action.payload.fields
      };
    case ADD_INVOICE_FIELD:
      return {
        ...state,
        fields: [
          ...state.fields,
          action.payload.field
        ]
      };
    case UPDATE_INVOICE_FIELD:
      const key = action.payload.key;
      const field: Field = action.payload.field;

      const fields = _.map(state.fields, (record) => {
        if (record.key === key) {
          return {
            ...record,
            ...field
          };
        }
        return record;
      });

      return {
        ...state,
        fields
      };
    case UPDATE_INVOICE_FIELD_IF_EMPTY:
      const key1 = action.payload.key;
      const priority = action.payload.priority;
      const field1: Field = action.payload.field;

      const fields1 = _.map(state.fields, (record) => {
        if (record.key === key1) {
          const result1 = {};

          for (const key2 in record) {
            if (record.hasOwnProperty(key2)) {
              result1[key2] = record[key2];
              if (field1.hasOwnProperty(key2)) {
                if (priority === 1) {
                  if (!record[key2]) {
                    result1[key2] = field1[key2];
                  }
                } else if (priority === 2) {
                  if (!record[key2] && field1[key2]) {
                    result1[key2] = field1[key2];
                  }
                } else if (priority === 3) {
                  if (field1[key2]) {
                    result1[key2] = field1[key2];
                  }
                }
              }
            }
          }

          return result1;
        }
        return record;
      });

      return {
        ...state,
        fields: fields1
      };
    
      
    case ADD_DOCUMENT_TYPE:

      const types: Array<DocTypeIdentity> = action.payload.documentTypes;
      const initialValue = action.payload.initialValue;
      const fieldNew = Invoice.getFieldDocumentType(types,_.isNumber(initialValue)?initialValue.toString():initialValue);

      return {
        ...state,
        fields: [
          fieldNew,
          ...state.fields
        ]
      };  
    default:
      return state;
  }
}
