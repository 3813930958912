import { initialUserState, IUser, parseUserAddresses, parseUserCards } from '../core/models/user.model';

export const REMOVE_USER = 'REMOVE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
export const UPDATE_USER_CARDS = 'UPDATE_USER_CARDS';

export function userReducer(state: IUser = initialUserState, action: any): any {
  switch (action.type) {
    case UPDATE_USER:
      return action.payload;
    case UPDATE_USER_ADDRESS:
      return {
        ...state,
        addresses: parseUserAddresses(action.payload.addresses)
      };
    case UPDATE_USER_CARDS:
      return {
        ...state,
        cards: parseUserCards(action.payload.cards)
      };
    case REMOVE_USER:
      return initialUserState;
    default:
      return state;
  }
}
