import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  constructor(private logger: NGXLogger) {}

  load(config): Promise<any> {
    const promises: any[] = [];
    // promises.push(this.loadScript('fingerprint', `https://h.online-metrix.net/fp/tags.js?org_id=${config.orgId}&session_id=${config.merchantId}${config.uuid}`));
    // promises.push(this.loadScript('songbird', environment.songbirdURL));
    return Promise.all(promises);
  }

  loadScript(name: string, scriptURL: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = scriptURL;
      script.onload = () => {
        this.logger.info(`${name} Loaded. ${scriptURL}`);
        resolve({ script: name, status: 'Loaded' });
      };
      script.onerror = (error: any) => {
        this.logger.error(`${name} failed. ${scriptURL}`);
        reject(error);
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
}
