import { Injectable } from '@angular/core';
import HttpUtil from '../utils/http.util';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private httpUtil;

  constructor() {
    this.httpUtil = HttpUtil.Instance;
  }

  public getUserData(id, token): Promise<any> {
    return this.httpUtil.execute({
      url: `/micro-pagos/usuario/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  public updateUserPassword(id, token, passwordData): Promise<any> {
    return this.httpUtil.execute({
      url: `/usuarios/${id}/password-reset`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        confirm: passwordData.confirmPassword,
        newPassword: passwordData.newPassword,
        oldPassword: passwordData.oldPassword
      }
    });
  }

  public getAddresses(id, token): Promise<any> {
    return this.httpUtil.execute({
      url: `/micro-pagos/usuario/${id}/direccion`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  public getCards(id, token, taxPayerId): Promise<any> {
    return this.httpUtil.execute({
      url: `/micro-pagos/usuario/${id}/tarjeta/contribuyente/${taxPayerId}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  public removeCard(id, token, taxPayerId, cardId): Promise<any> {
    return this.httpUtil.execute({
      url: `/micro-pagos/usuario/${id}/tarjeta/${cardId}/contribuyente/${taxPayerId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
