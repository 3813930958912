<div class="payment-summary">
  <div class="body--big-b--grey pb-2 mt-4">
    {{ "payment.summary.title" | translate }}
  </div>
  <div class="pb-2">
    <app-custom-panel [noMargin]="true">
      <div class="d-flex justify-content-between">
        <div class="body-b--dark">
          {{ "payment.summary.invoice_title" | translate }}
        </div>
        <a
          *ngIf="!(order$ | async).config.prefactura"
          class="body-b--blue a-link--no-decoration"
          (click)="open(invoiceDataModal)"
        >
          {{ "common.edit" | translate }}
        </a>
      </div>
      <div class="d-flex">
        <div class="">
          <ng-container *ngIf="invoice$ && (invoice$ | async).fields">
            <ng-container *ngFor="let field of (invoice$ | async).fields">
              <div>
                <span class="body--grey"
                  >{{ field.shortLabel | translate }}:</span
                >&nbsp; <span class="body-m--darkgrey">{{ field.value }}</span>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </app-custom-panel>
  </div>
  <app-custom-panel [noMargin]="true">
    <div *ngIf="order$ && (order$ | async).items">
      <table class="w-100">
        <thead>
          <tr>
            <th></th>
            <th class="pb-3 body--small-m--grey">
              {{ "payment.summary.item" | translate }}
            </th>
            <th class="text-end pb-2 body--small-m--grey">
              {{ "payment.summary.price" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of (order$ | async).items">
            <td class="body--darkgrey">x{{ item.quantity }}</td>
            <td class="body-m--darkgrey">{{ item.name | utf8Decode }}</td>
            <td class="text-end body-m--darkgrey">
              {{ item.price | currency: (order$ | async).currency:"symbol-narrow" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="divider--grey-25 my-3"></div>
    <div *ngIf="order$ && (order$ | async).total">
      <div class="d-flex mt-2 mb-1">
        <div class="body-m--darkgrey">
          {{ "payment.summary.subtotal" | translate }}
        </div>
        <div class="ms-auto body-m--dark">
          {{ (order$ | async).subtotal | currency: (order$ | async).currency:"symbol-narrow" }}
        </div>
      </div>
      <div class="d-flex mt-2 mb-1">
        <div class="body-m--darkgrey">
          {{ "payment.summary.discount" | translate }}
        </div>
        <div class="ms-auto body-m--dark">
          {{ ((order$ | async).subtotal-(order$ | async).total) | currency: (order$ | async).currency:"symbol-narrow" }}
        </div>
      </div>

      <div class="d-flex mb-2" *ngIf="order$ && (order$ | async).delivery">
        <div class="body-m--darkgrey">
          {{ "payment.summary.delivery" | translate }}
        </div>
        <div class="ms-auto body-m--dark">
          {{ (order$ | async).delivery | currency: (order$ | async).currency:"symbol-narrow" }}
        </div>
      </div>
      

      <div class="d-flex bd-highlight mt-2 mb-1 total-wrapper" *ngIf="(order$ | async).partial">
        <div class="body-m--darkgrey">
          {{ "payment.summary.total_payment_invoice" | translate }}
        </div>
        <div class="ms-auto body-m--dark">
          {{ (order$ | async).total | currency: (order$ | async).currency:"symbol-narrow" }}
        </div>
      </div>

      <div class="d-flex bd-highlight mb-3 total-wrapper" *ngIf="!(order$ | async).partial">
        <div class="body--big-m--grey">
          {{ "payment.summary.total" | translate }}
        </div>
        <div class="ms-auto body--big-b--dark">
          {{ (order$ | async).total | currency: (order$ | async).currency:"symbol-narrow" }}
        </div>
      </div>

      <div class="d-flex bd-highlight mb-3 total-wrapper" *ngIf="(order$ | async).partial">
        <div class="body--big-m--grey">
          {{ "payment.summary.total" | translate }}
        </div>
        <div class="ms-auto body--big-b--dark">
          {{ (order$ | async).partial | currency: (order$ | async).currency:"symbol-narrow" }}
        </div>
      </div>
    </div>

    <div
      class="d-grid gap-2 col-12 mx-auto py-4 payment-button-wrapper"
      *ngIf="selectedPaymentMethod === 'card'"
    >
      <button
        type="button"
        class="a-izi-btn--medium a-izi-btn--medium--secondary"
        (click)="executePayment()"
      >
        {{ "common.pay" | translate }}
      </button>
    </div>
  </app-custom-panel>

  <div class="footer-spacer"></div>

  <ng-template #invoiceDataModal let-modal>
    <div class="modal-header">
      <div class="title--small--dark" id="modal-basic-title">
        {{ "payment.summary.invoice_title" | translate }}
      </div>
    </div>
    <div class="modal-body">
      <form #formPago="ngForm" (ngSubmit)="modal.close()">
        <ng-container *ngIf="invoiceTmp$?.getValue().fields">
          <ng-container *ngFor="let field of invoiceTmp$.getValue().fields">
            <div class="mb-3">
              <ng-container *ngIf="field.type === 'text'">
                <label
                  class="label--darkgrey--85"
                  [for]="field.key"
                  [ngClass]="{ required: field.required }"
                  >{{ field.label | translate }}</label
                >
                <input
                  type="text"
                  class="a-input-form-group__input"
                  [name]="field.key"
                  [id]="field.key"
                  placeholder="{{ field.placeholder | translate }}"
                  [ngModel]="field.value"
                  [readonly]="field.readonly"
                  [required]="field.required"
                  (change)="updateInvoiceFieldValue(field.key, $event.target)"
                />
              </ng-container>

              <ng-container *ngIf="field.type === 'select'">
                <label
                  class="label--darkgrey--85"
                  [for]="field.key"
                  [ngClass]="{ required: field.required }"
                  >{{ field.label | translate }}</label
                >
                <select
                  class="form-select custom-select"
                  [name]="field.key"
                  [id]="field.key"
                  [ngModel]="field.value"
                  [disabled]="field.readonly"
                  (change)="updateInvoiceFieldValue(field.key, $event.target)"
                  [required]="field.required"
                >
                  <option value="" class="text-black-50">
                    {{ field.placeholder | translate }}
                  </option>
                  <option
                    *ngFor="let option of parseSelectorOptions(field.options)"
                    [value]="option.value?option.value:option"
                  >
                    {{ option.text?option.text:option }}
                  </option>
                </select>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>

        <div class="d-grid gap-2 col-9 mx-auto py-4">
          <button
            type="submit"
            class="a-izi-btn--medium a-izi-btn--medium--primary"
            [disabled]="!formPago.form.valid"
          >
            {{ "common.change" | translate }}
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
