<div class="app-login mt-5 container">
  <div class="row align-items-center">
    <div class="col-md-7 col-sm-12">
      <div class="aux-login-center__text">
        <p class="mb-4 body--big--darkgrey">
          {{ "account.dont_have_an_account" | translate }}
          <a
            class="a-link--big body--big-b--primary ms-1"
            (click)="goToRegister()"
          >
            {{ "account.create_account_link" | translate }}
          </a>
        </p>
      </div>

      <app-custom-panel [defaultWidth]="true" class="aux-login-center">
        <div class="title--big--dark mb-3">
          {{ "login.enter_izi" | translate }}
        </div>

        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="pruebas">
          <div class="mb-3">
            <label for="username" class="label--darkgrey--85">{{
              "field.username.label" | translate
            }}</label>
            <input
              type="text"
              formControlName="username"
              class="a-input-form-group__input"
              id="username"
              name="username"
              placeholder="{{ 'field.username.placeholder' | translate }}"
              required
            />
            <ng-container *ngIf="f.username.errors && f.username.touched">
              <div *ngIf="f.username.errors.required" class="a-input--error">
                {{ "field.username.error.required" | translate }}
              </div>
            </ng-container>
          </div>

          <div class="mb-3">
            <label for="password" class="label--darkgrey--85">{{
              "field.password.label" | translate
            }}</label>
            <input
              type="password"
              formControlName="password"
              class="a-input-form-group__input"
              id="password"
              name="password"
              placeholder="{{ 'field.password.placeholder' | translate }}"
              required
            />
            <ng-container *ngIf="f.password.errors && f.password.touched">
              <div *ngIf="f.password.errors.required" class="a-input--error">
                {{ "field.password.error.invalid" | translate }}
              </div>
            </ng-container>
          </div>

          <div class="pb-4 mt-2">
            <a type="button" class="a-link body-b--dark" (click)="goToForgot()">
              {{ "common.forgot_password" | translate }}
            </a>
          </div>

          <div class="">
            <button
              type="submit"
              class="a-izi-btn--medium a-izi-btn--medium--primary"
              [disabled]="!loginForm.valid"
            >
              {{ "common.login_app" | translate }}
            </button>
          </div>
        </form>
      </app-custom-panel>
    </div>
    <div class="col-md-5 col-sm-12">
      <img src="assets/images/reel1.svg" alt="" />
    </div>
  </div>
</div>
