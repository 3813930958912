import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { NavigatorStep } from '../../states/navigator.state';
import { Observable, Subscription } from 'rxjs';
import { IDelivery } from '../../core/models/delivery.model';
import * as _ from 'lodash';
import { CityService } from '../../services/city.service';
import { UPDATE_DELIVERY_SCHEDULE } from '../../reducers/delivery.reducer';
import { take } from 'rxjs/operators';
import { NavigatorService } from '../../services/navigator.service';
import { PaymentService } from '../../services/payment.service';
import { ActivatedRoute } from '@angular/router';
import { Address } from '../../core/models/address.model';
import moment from 'moment';
import DataUtil from '../../utils/data.util';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, OnDestroy {
  icon = {
    faMapMarkerAlt
  };

  paymentId: string;

  delivery$: Observable<IDelivery>;

  selectedPeriod: string;

  cityName: string;
  date: string;

  subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private store: Store<AppState>,
              private cityService: CityService,
              private navigator: NavigatorService,
              private paymentService: PaymentService) {
    this.delivery$ = this.store.select(state => state.delivery);
  }

  ngOnInit(): void {
    this.paymentId = this.route.snapshot.paramMap.get('id');
    this.selectedPeriod = 'morning';
    this.delivery$.pipe(take(1)).subscribe((data) => this.updateDeliveryData(data));
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (subscription) => subscription.unsubscribe());
  }

  async updateDeliveryData(data: IDelivery): Promise<void> {
    const city = await this.cityService.getCity(data.address.city, data.address.country);
    this.cityName = _.get(city, ['0', 'nombre']);
    await this.getDeliveryDate(data.address);
  }

  async getDeliveryDate(address: Address): Promise<void> {
    this.date = await this.paymentService.getDeliveryDate(this.paymentId, address);
  }

  onSubmit(): void {
    this.store.dispatch({
      type: UPDATE_DELIVERY_SCHEDULE,
      payload: {
        date: this.date,
        period: this.selectedPeriod
      }
    });

    this.goToPaymentSection();
  }

  goToAddressSelector(): void {
    if (environment.steps.addressEnabled) {
      this.navigator.go(NavigatorStep.delivery);
    }
  }

  goToPaymentSection(): void {
    this.navigator.go(NavigatorStep.payment);
  }

  selectPaymentMethod(value: any): void {
    this.selectedPeriod = DataUtil.getValue(value);
  }

  formatDate(date): string {
    const localLocale = moment(date);
    moment.locale('es');
    localLocale.locale(false);
    return localLocale.format('dddd, DD MMMM YYYY');
  }
}
