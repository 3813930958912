<div class="app-login container">
  <ng-container *ngIf="taxpayer && taxpayer.id">
    <img
      [src]="baseURL + 'contribuyentes/' + taxpayer.id + '/logo'"
      alt=""
      class="brand-logo mt-1 mb-4"
      [ngClass]="taxpayer.logoOrientation"
    />
  </ng-container>

  <ng-container *ngIf="user$ && ((user$ | async).id || (user$ | async).uuid)">
    <div class="title--big--dark text-center">
      {{ "invoice.invoice_data" | translate }}
    </div>
  </ng-container>

  <ng-container
    *ngIf="!(user$ && ((user$ | async).id || (user$ | async).uuid))"
  >
    <div class="fs-5 fw-bold text-center">
      {{ "invoice.continue_as_guest" | translate }}
    </div>
  </ng-container>

  <app-custom-panel [defaultWidth]="true">
    <div *ngIf="order$ && (order$ | async).total && (order$ | async).items">
      <div class="d-flex">
        <h5 class="body-m--darkgrey">
          {{ "payment.summary.items" | translate }} ({{
            (order$ | async).items.length
          }})
        </h5>
        <h5 class="ms-auto body-m--dark">
          {{ (order$ | async).subtotal | currency: (order$ | async).currency:"symbol-narrow" }}
        </h5>
      </div>
      <div class="d-flex">
        <h5 class="body-m--darkgrey">
          {{ "payment.summary.discount" | translate }}
        </h5>
        <h5 class="ms-auto body-m--dark">
          {{ ((order$ | async).subtotal-(order$ | async).total) | currency: (order$ | async).currency:"symbol-narrow" }}
        </h5>
      </div>

      <div class="d-flex"  *ngIf="order$ && (order$ | async).delivery">
        <h5 class="body-m--darkgrey">
          {{ "payment.summary.delivery_short" | translate }}
        </h5>
        <h5 class="ms-auto body-m--dark">
          {{ (order$ | async).delivery | currency: (order$ | async).currency:"symbol-narrow" }}
        </h5>
      </div>
      <div class="d-flex"  *ngIf="(order$ | async).partial">
        <h5 class="body-m--darkgrey">
          {{ "payment.summary.total_payment_invoice" | translate }}
        </h5>
        <h5 class="ms-auto body-m--dark"> 
          {{ (order$ | async).total | currency: (order$ | async).currency:"symbol-narrow" }}
        </h5>
      </div>
      <div class="d-flex" *ngIf="!(order$ | async).partial">
        <h5 class="body--big-m--grey">
          {{ "payment.summary.total_payment" | translate }}
        </h5>
        <h5 class="ms-auto body--big-b--dark"> 
          {{ (order$ | async).total | currency: (order$ | async).currency:"symbol-narrow" }}
        </h5>
      </div>

      <div class="d-flex" *ngIf="(order$ | async).partial">
        <h5 class="body--big-m--grey">
          {{ "payment.summary.total_payment" | translate }}
        </h5>
        <h5 class="ms-auto body--big-b--dark"> 
          {{ (order$ | async).partial | currency: (order$ | async).currency:"symbol-narrow" }}
        </h5>
      </div>
    </div>
  </app-custom-panel>

  <app-custom-panel [defaultWidth]="true">
    <p class="body--big-b--dark mb-2">Ingresa tus datos de facturación</p>
    <form #formPago="ngForm" (ngSubmit)="onSubmit()">
      <ng-container *ngIf="invoice$ && (invoice$ | async).fields">
        <ng-container
          *ngFor="
            let field of (invoice$ | async).fields;
            let i = index;
            let fieldName = i
          "
        >
          <div class="mb-3">
            <ng-container *ngIf="field.type === 'text'">
              <label
                class="label--darkgrey--85"
                [for]="field.key"
                [ngClass]="{ required: field.required }"
                >{{ field.label | translate }}</label
              >
              <input
                type="text"
                class="a-input-form-group__input"
                [name]="field.key"
                [id]="field.key"
                placeholder="{{ field.placeholder | translate }}"
                [ngModel]="field.value"
                [required]="field.required"
                [readonly]="field.readonly"
                (change)="updateInvoiceFieldValue(field.key, $event.target)"
                #fieldName="ngModel"
              />
              <div
                *ngIf="
                  fieldName.invalid && (fieldName.dirty || fieldName.touched)
                "
                class="a-input--error"
              >
                <ng-container *ngIf="fieldName.errors?.required">
                  {{ (field.errors.required || "common.field_required" ) | translate}}
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="field.type === 'select'">
              <label
                class="label--darkgrey--85"
                [for]="field.key"
                [ngClass]="{ required: field.required }"
                >{{ field.label | translate }}</label
              >
              <select
                class="form-select custom-select"
                [name]="field.key"
                [id]="field.key"
                [ngModel]="field.value"
                [disabled]="field.readonly"
                [ngClass]="{ 'form-select__placeholder': !field.value }"
                (change)="updateInvoiceFieldValue(field.key, $event.target)"
                [required]="field.required"
                #fieldName="ngModel"
              >
                <option value="">
                  {{ field.placeholder | translate }}
                </option>
                <option
                  class="body--dark"
                  *ngFor="let option of parseSelectorOptions(field.options)"
                  [value]="option.value?option.value:option"
                >
                  {{ option.text?option.text:option }}
                </option>
              </select>
              <div
                *ngIf="
                  fieldName.invalid && (fieldName.dirty || fieldName.touched)
                "
                class="a-input--error"
              >
                <ng-container *ngIf="fieldName.errors?.required">
                  {{ (field.errors.required || "common.field_required" ) | translate}}
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="field.type === 'number'">
              <label
                class="label--darkgrey--85"
                [for]="field.key"
                [ngClass]="{ required: field.required }"
                >{{ field.label | translate }}</label
              >
              <input
                type="number"
                class="a-input-form-group__input"
                [name]="field.key"
                [id]="field.key"
                placeholder="{{ field.placeholder | translate }}"
                [ngModel]="field.value"
                [required]="field.required"
                (change)="updateInvoiceFieldValue(field.key, $event.target)"
                #fieldName="ngModel"
              />
              <div
                *ngIf="
                  fieldName.invalid && (fieldName.dirty || fieldName.touched)
                "
                class="a-input--error"
              >
                <ng-container *ngIf="fieldName.errors?.required">
                  {{ field.errors.required | translate }}
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <!-- <div class="d-grid gap-2 col-9 mx-auto pt-4"> -->
      <div class="centered-element">
        <button
          type="submit"
          class="a-izi-btn--medium a-izi-btn--medium--primary my-4"
          [disabled]="!formPago.form.valid"
        >
          {{ "common.next" | translate }}
        </button>
      </div>
      <!-- </div> -->
    </form>
  </app-custom-panel>

  <div
    *ngIf="user$ && !((user$ | async).id || (user$ | async).uuid)"
    class="content-wrapper no-border default-width"
  >
    <div class="centered-element text-center d-flex align-items-center mt-3">
      <div class="body--big-b--darkgrey">{{ "account.title" | translate }}</div>
      <app-custom-logo [small]="true"></app-custom-logo>
    </div>

    <div class="centered-element pt-3 pb-2">
      <p class="body--big--darkgrey">{{ "account.advantage" | translate }}</p>
    </div>

    <div class="d-grid gap-2 col-9 mx-auto">
      <button
        type="button"
        class="a-izi-btn--medium a-izi-btn--medium--terciary"
        (click)="goToLogin()"
      >
        {{ "common.login" | translate }}
      </button>
    </div>

    <div class="centered-element body--big--darkgrey pt-4 mb-2">
      {{ "account.dont_have_an_account" | translate }}
      <a class="a-link--big body--big-b--primary ms-1" (click)="goToRegister()">
        {{ "account.create_account_link" | translate }}
      </a>
    </div>
  </div>
</div>
