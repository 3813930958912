import { Component, OnInit } from '@angular/core';
import { Pago } from '../../core/models';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { NavigatorStep } from '../../states/navigator.state';
import { Observable } from 'rxjs';
import { IUser } from '../../core/models/user.model';
import { IPayment } from '../../core/models/payment.model';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { Taxpayer } from '../../core/models/taxpayer.model';
import { IInvoice } from '../../core/models/invoice.model';
import { UPDATE_INVOICE_FIELD } from '../../reducers/invoice.reducer';
import { NavigatorService } from '../../services/navigator.service';
import DataUtil from '../../utils/data.util';
import { IOrder } from 'src/app/core/models/order.model';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  user$: Observable<IUser>;

  order$: Observable<IOrder>;

  payment$: Observable<IPayment>;

  pago: Pago;

  taxpayer: Taxpayer;

  baseURL = environment.apiURL;

  invoice$: Observable<IInvoice>;

  constructor(private store: Store<AppState>,
              private navigator: NavigatorService) {
    this.user$ = this.store.select(state => state.user);
    this.invoice$ = this.store.select(state => state.invoice);
    this.order$ = this.store.select(state => state.order);
  }

  ngOnInit(): void {
    this.taxpayer = new Taxpayer();
    this.payment$ = this.store.select(state => state.payment);
    this.payment$.subscribe((data) => this.updatePaymentForm(data));
  }

  updatePaymentForm(data: IPayment): void {
    this.taxpayer = _.cloneDeep(data.taxpayer);
  }

  parseSelectorOptions(data: any): Array<any> {
    if(_.isObject(data)){
      return data as Array<any>;
    }
    return data.split(',');
  }

  onSubmit(): void {
    if (environment.steps.addressEnabled) {
      this.navigator.go(NavigatorStep.delivery);
    } else if (environment.steps.scheduleEnabled) {
      this.navigator.go(NavigatorStep.schedule);
    } else {
      this.navigator.go(NavigatorStep.payment);
    }
  }

  goToLogin(): void {
    this.navigator.go(NavigatorStep.login);
  }

  goToRegister(): void {
    this.navigator.go(NavigatorStep.register);
  }

  updateInvoiceFieldValue(key: string, value: any): void {
    this.store.dispatch({
      type: UPDATE_INVOICE_FIELD,
      payload: {
        key,
        field: {
          value: DataUtil.getValue(value)
        }
      }
    });
  }
}
