import { Socket } from 'ngx-socket-io';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  constructor(private socket: Socket) {}

  getPaymentSocketNotification(): any {
    return this.socket.fromEvent('payment-notification');
  }
}
