<div class="app-delivery">
  <div class="body--big-b--darkgrey text-center mt-5">
    {{ "delivery.title" | translate }}
  </div>

  <ng-container *ngIf="deliveryTmp$ && deliveryTmp$.getValue()">
    <app-custom-panel [defaultWidth]="true">
      <form #formDelivery="ngForm" (ngSubmit)="openSearchMap(mapModal)">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="country" class="label--darkgrey--85">{{
                "field.country_delivery.label" | translate
              }}</label>
              <select
                class="form-select"
                name="country"
                id="country"
                [ngModel]="deliveryTmp$.getValue().address.country"
                (change)="updateCountry($event.target)"
                required
              >
                <option value="" disabled>
                  {{ "field.country_delivery.placeholder" | translate }}
                </option>
                <option
                  *ngFor="let country of countryList"
                  [value]="country.id"
                >
                  {{ country.nombre }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label for="city" class="label--darkgrey--85">{{
                "field.state.label" | translate
              }}</label>
              <select
                class="form-select"
                name="city"
                id="city"
                [ngModel]="deliveryTmp$.getValue().address.city"
                (change)="updateCityValue($event.target)"
                required
              >
                <option value="" disabled>
                  {{ "field.state.placeholder" | translate }}
                </option>
                <option *ngFor="let city of cities.cities" [value]="city.id">
                  {{ city.nombre }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="address" class="label--darkgrey--85">{{
            "field.address.label" | translate
          }}</label>
          <input
            type="text"
            class="a-input-form-group__input"
            name="address"
            id="address"
            placeholder="{{ 'field.address.placeholder' | translate }}"
            [ngModelOptions]="{ standalone: true }"
            #address="ngModel"
            [ngModel]="deliveryTmp$.getValue().address.address"
            (change)="updateAddressValue($event.target)"
            required
          />
          <span *ngIf="!address.valid && address.touched">
            {{ "field.address.error.invalid" | translate }}
          </span>
        </div>

        <div class="mb-3">
          <label for="description" class="label--darkgrey--85">{{
            "field.description.label" | translate
          }}</label>
          <textarea
            class="a-input-textarea"
            name="description"
            id="description"
            rows="3"
            placeholder="{{ 'field.description.placeholder' | translate }}"
            [ngModelOptions]="{ standalone: true }"
            #description="ngModel"
            [ngModel]="deliveryTmp$.getValue().address.description"
            (change)="updateDescriptionValue($event.target)"
            required
          >
          </textarea>
          <span *ngIf="!description.valid && description.touched">
            {{ "field.description.error.invalid" | translate }}
          </span>
        </div>

        <div
          *ngIf="user$ && ((user$ | async).id || (user$ | async).uuid)"
          class="form-check mb-3"
        >
          <input
            class="a-input-form-group__input"
            type="checkbox"
            id="save"
            name="save"
            [checked]="deliveryTmp$.getValue().address.save"
            [ngModel]="deliveryTmp$.getValue().address.save"
            (change)="updateSaveValue($event.target)"
          />
          <label class="label--darkgrey--85" for="save">
            {{ "delivery.save_address" | translate }}
          </label>
        </div>

        <div class="d-grid gap-2 col-9 mx-auto py-4">
          <button
            type="submit"
            class="a-izi-btn--medium a-izi-btn--medium--primary"
            [disabled]="!formDelivery.form.valid"
          >
            {{ "common.search" | translate }}
          </button>
        </div>
      </form>
    </app-custom-panel>
  </ng-container>

  <div *ngIf="user$ && (user$ | async).addresses.length > 0" class="py-4">
    <div class="fw-bold fs-5 text-center">
      {{ "delivery.saved_address" | translate }}
    </div>
    <ng-container *ngFor="let address of (user$ | async).addresses">
      <app-custom-panel [large]="true">
        <div class="d-flex bd-highlight mt-2 align-items-center">
          <div class="p-2 bd-highlight fw-bold address">
            <fa-icon class="fa-izi" [icon]="icon.faMapMarkerAlt"></fa-icon>
          </div>
          <div class="p-2 bd-highlight fw-bold address">
            {{ address.address }}
            <br />
            {{ getCityName(address.city) }} {{ address.country }}
          </div>
          <div class="ms-auto p-2 bd-highlight fw-bold">
            <button
              type="button"
              class="btn btn-link"
              (click)="selectAddress(address)"
            >
              {{ "common.select" | translate }}
            </button>
            <br />
            <button
              type="button"
              class="btn btn-link"
              (click)="viewMap(mapModal, address)"
            >
              {{ "common.view_map" | translate }}
            </button>
          </div>
        </div>
      </app-custom-panel>
    </ng-container>
  </div>

  <ng-template #mapModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        <fa-icon class="fa-izi" [icon]="icon.faMapMarkerAlt"></fa-icon>
        {{
          "delivery.map_header"
            | translate: { address: deliveryTmp$.getValue().address.address }
        }}
      </h4>
    </div>
    <div class="modal-body">
      <agm-map
        class="map"
        [latitude]="initialGeoPosition.lat"
        [longitude]="initialGeoPosition.long"
        [zoom]="15"
        [disableDefaultUI]="true"
        (mapReady)="mapReadyHandler($event)"
      >
        <agm-fullscreen-control></agm-fullscreen-control>
        <agm-zoom-control></agm-zoom-control>
        <agm-marker
          [latitude]="deliveryTmp$.getValue().address.latitude"
          [longitude]="deliveryTmp$.getValue().address.longitude"
          [markerDraggable]="true"
          [markerClickable]="true"
          (dragEnd)="onMarkerChange($event)"
          (change)="onMarkerChange($event)"
        >
        </agm-marker>
      </agm-map>

      <div class="d-grid gap-2 col-4 mx-auto py-4">
        <button
          type="button"
          class="btn btn-primary text-uppercase"
          (click)="modal.close()"
        >
          {{ "common.confirm" | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
