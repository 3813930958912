<div class="app-login container">
  <div class="row align-items-center">
    <div class="col-md-7 col-sm-12">
      <div class="mt-5">
        <app-custom-panel [defaultWidth]="true">
          <form #formLogin="ngForm" (ngSubmit)="onSubmit()">
            <h1 class="title--big--dark mb-2">Recupera tu contraseña</h1>
            <p class="body--darkgrey mb-4">
              Te enviaremos un código a tu correo para que puedas crear una
              nueva contraseña.
            </p>

            <div class="mb-4">
              <label for="email" class="label--darkgrey--85">{{
                "field.email.label" | translate
              }}</label>
              <input
                type="text"
                class="a-input-form-group__input"
                id="email"
                name="email"
                placeholder="{{ 'field.email.placeholder' | translate }}"
                [(ngModel)]="email"
                required
              />
            </div>
            <div class="mb-4 pt-2">
              <button
                type="submit"
                class="a-izi-btn--medium a-izi-btn--medium--primary"
                [disabled]="!formLogin.form.valid"
              >
                {{ "common.send_code" | translate }}
              </button>
            </div>

            <div class="pt-2">
              <a class="a-link body-b--blue" (click)="goToRestorePassword()">
                {{ "common.have_a_code" | translate }}
              </a>
            </div>
          </form>

          <div class="pt-4">
            <button
              type="button"
              class="a-izi-btn--medium a-izi-btn--medium--terciary"
              (click)="goToLogin()"
            >
              {{ "common.login" | translate }}
            </button>
          </div>
        </app-custom-panel>
      </div>
    </div>
    <div class="col-md-5 col-sm-12">
      <img src="assets/images/reel1.svg" alt="" />
    </div>
  </div>
</div>
