import { initialLoaderState, Loader } from '../states/loader.state';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export function loaderReducer(state: Loader = initialLoaderState, action): any {
  let counter;
  switch (action.type) {
    case SHOW_LOADER:
      counter = state.counter + 1;
      return {
        visibility: counter > 0,
        counter
      };
    case HIDE_LOADER:
      counter = state.counter + (state.counter === 0 ? 0 : -1);
      return {
        visibility: counter > 0,
        counter
      };
    default:
      return state;
  }
}
