export interface ICard {
  cardType: string;
  country: string;
  city: string;
  name: string;
  cardNumber: string;
  ccv: string;
  expirationDate: string;
  id: string;
  save: boolean;
  type: string;
  address: string;
  token: string;
}

export class Card implements ICard {
  cardType: string;
  country: string;
  city: string;
  name: string;
  cardNumber: string;
  ccv: string;
  expirationDate: string;
  id: string;
  save: boolean;
  type: string;
  address: string;
  token: string;

  constructor(record?: ICard) {
    this.cardType = record && record.cardType || 'debit';
    this.type = record && record.type || '';
    this.country = record && record.country || '';
    this.city = record && record.city || '';
    this.name = record && record.name || '';
    this.cardNumber = record && record.cardNumber || '';
    this.ccv = record && record.ccv || '';
    this.expirationDate = record && record.expirationDate || '';
    this.id = record && record.id || null;
    this.save = record && record.save || false;
    this.address = record && record.address || '';
    this.token = record && record.token || '';
  }
}

export const initialCardState: ICard = new Card();
