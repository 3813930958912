import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Observable } from 'rxjs';
import { Loader } from '../../../states/loader.state';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  loader$: Observable<Loader>;
  visible: boolean;

  constructor(private store: Store<AppState>) {
    this.loader$ = this.store.select(state => state.loader);
    this.loader$.subscribe((data) => this.updateVisibility(data));
  }

  updateVisibility(data: Loader): void {
    this.visible = data.visibility;
  }
}
