<div class="header w-100 p-4" [ngClass]="{ 'border-bottom': displaySeparator }">
  <div class="left-panel">
    <ng-container *ngIf="navigator$ && (navigator$ | async).steps.length">
      <fa-icon
        class="fs-1 d-block d-md-none"
        [icon]="faChevronLeft"
        (click)="back()"
      ></fa-icon>
    </ng-container>

    <ng-container *ngIf="displayMainBrandLogo">
      <img
        src="../../../../assets/images/izi-logo.png"
        alt="iZi"
        class="brand"
      />
    </ng-container>

    <ng-container *ngIf="displayBrandLogo">
      <img
        [src]="baseURL + 'contribuyentes/' + taxpayer.id + '/logo'"
        class="brand"
        alt=""
      />
    </ng-container>
  </div>

  <div class="center-panel title--medium--dark">
    <ng-container *ngIf="displayPageTitle">
      {{ pageTitle | translate }}
    </ng-container>
  </div>

  <div class="right-panel">
    <ng-container *ngIf="displayPaymentSiteLogo">
      <div class="right-panel-content">
        <div class="panel-content">
          <span class="text-black-50">{{ "supported_by" | translate }}</span>
          <app-custom-logo></app-custom-logo>
        </div>

        <ng-container
          *ngIf="user$ && ((user$ | async).id || (user$ | async).uuid)"
        >
          <div class="dropdown-container" ngbDropdown>
            <button
              class="btn btn-link btn-custom-normal"
              id="dropdownForm1"
              ngbDropdownToggle
            >
              {{ getName(user$ | async) }}
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="navbarDropdown2"
              class="dropdown-menu dropdown-menu-right"
            >
              <div class="d-grid gap-2 col-12 border-bottom p-3">
                <div class="field-wrapper">
                  <label class="text-black-50">{{
                    "field.name.label" | translate
                  }}</label>
                  <div class="fw-bold">{{ getName(user$ | async) }}</div>
                </div>

                <div class="field-wrapper">
                  <label class="text-black-50">{{
                    "field.email.label" | translate
                  }}</label>
                  <div class="fw-bold">{{ (user$ | async).email }}</div>
                </div>
              </div>

              <div class="d-grid gap-2 col-12 border-bottom">
                <button
                  type="button"
                  class="btn btn-link btn-custom-normal text-start"
                  (click)="openChangePasswordModal(changePasswordModal)"
                >
                  {{ "common.change_password" | translate }}
                </button>
              </div>
              <div class="d-grid gap-2 col-12">
                <button
                  type="button"
                  class="btn btn-link btn-custom-alert text-start"
                  (click)="logout()"
                >
                  {{ "common.logout" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="displayBackLink">
      <!-- <div class="panel-content">
        <button type="button" class="btn btn-link" (click)="goToInvoice()">
          {{ "common.go_home" | translate }}
        </button>
      </div> -->
      <a class="a-link body-b--dark" (click)="goToInvoice()">
        {{ "common.go_home" | translate }}
      </a>
    </ng-container>
  </div>

  <ng-template #changePasswordModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ "change_password.title" | translate }}
      </h4>
    </div>
    <div class="modal-body">
      <app-change-password
        (changeCompleted)="onChangeCompleted($event, modal)"
        (changeFailed)="onChangeFailed($event, modal)"
      ></app-change-password>
    </div>
  </ng-template>
</div>
