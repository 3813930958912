import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigatorStep } from '../../states/navigator.state';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterService } from '../../services/register.service';
import { NavigatorService } from '../../services/navigator.service';
import { environment } from '../../../environments/environment';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { MustMatch } from '../../validators/must-match.validator';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  faEyeSlash = faEyeSlash;

  faEye = faEye;

  public showPassword: boolean;

  public showConfirmPassword: boolean;

  registerForm: FormGroup;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private registerService: RegisterService,
    private navigator: NavigatorService,
    private formBuilder: FormBuilder,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.showPassword = false;
    this.showConfirmPassword = false;

    this.registerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f(): any {
    return this.registerForm.controls;
  }

  async onSubmit(): Promise<any> {
    try {
      await this.registerService.register(this.registerForm.value);
      this.navigator.go(NavigatorStep.login);
    } catch (e) {
      let errorMessage;
      try {
        const message = JSON.parse(e.message);
        errorMessage = message[0] ? message[0].message : message;
      } catch (e1) {
        errorMessage = e.message;
      }
      this.toastService.show(`Ha ocurrido un error al intentar registrar un usuario: ${errorMessage}`, { classname: 'bg-danger' });
    }
  }

  goToLogin(): void {
    this.navigator.go(NavigatorStep.login);
  }

  async open(content): Promise<void> {
    await this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result;
  }

  async showTermsAndConditions(content): Promise<void> {
    if (environment.termsAndConditions && environment.termsAndConditions !== '') {
      window.open(environment.termsAndConditions, '_blank');
    } else {
      await this.open(content);
    }
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
