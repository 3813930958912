import { Component, OnInit } from '@angular/core';
import { NavigatorStep } from '../../../states/navigator.state';
import { NavigatorService } from '../../../services/navigator.service';
import { environment } from '../../../../environments/environment';

import { faExclamationCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.scss']
})
export class PaymentFailedComponent implements OnInit {

  faExclamationCircle = faExclamationCircle;
  faPlusCircle = faPlusCircle;

  customAccordionClass = 'custom-accordion-panel';

  iziWebPage: string;

  constructor(private navigator: NavigatorService) { }

  ngOnInit(): void {
    this.iziWebPage = environment.iziWebPage;
  }

  goToIzi(): void {
    window.open(environment.relatedBusiness, '_blank');
  }

  goToInvoice(): void {
    this.navigator.go(NavigatorStep.invoice);
  }

  tryAgain(): void {
    this.navigator.go(NavigatorStep.payment);
  }
}

