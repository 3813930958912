<div class="payment container pb-4">
  <div class="row align-items-start">
    <div class="col-md-7 col-sm-12">
      <div class="payment-method mt-4">
        <div class="body--big-b--grey pb-2">
          {{ "payment.title" | translate }}
        </div>

        <app-payment-method
          (paymentMethodSelected)="updatePaymentMethod($event)"
        ></app-payment-method>

        <ng-container *ngIf="selectedPaymentMethod === 'card'">
          <app-payment-card
            (cardRemoved)="removeCard($event)"
            (cardUpdated)="updateCardData($event)"
          ></app-payment-card>
        </ng-container>

        <ng-container *ngIf="selectedPaymentMethod === 'qr'">
          <app-payment-qr
            [waiting]="qrWaiting"
            [qrData]="qrData"
          ></app-payment-qr>
        </ng-container>
      </div>
    </div>

    <div class="col-md-5 col-sm-12">
      <app-payment-summary
        [selectedPaymentMethod]="selectedPaymentMethod"
        (executePaymentEvent)="onExecutePaymentEvent()"
        (resetQrEvent)="resetQrEvent()"
      ></app-payment-summary>
    </div>
  </div>
</div>

<div class="footer border shadow-lg">
  <div *ngIf="order$ && (order$ | async).total">
    <div class="d-flex mt-2">
      <div class="body--big-m--grey">
        {{ "payment.summary.total_payment" | translate }}
      </div>
      <div class="ms-auto body--big-b--dark">
        {{ (order$ | async).total | currency: "BOB":"symbol-narrow" }}
      </div>
    </div>
  </div>

  <div
    class="d-grid gap-2 col-12 mx-auto py-3"
    *ngIf="selectedPaymentMethod === 'card'"
  >
    <button
      type="button"
      class="a-izi-btn--medium a-izi-btn--medium--primary"
      (click)="onExecutePaymentEvent()"
    >
      {{ "common.pay" | translate }}
    </button>
  </div>
</div>
