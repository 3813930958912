import { Pago } from './pago.model';
import { Payer } from './payer.model';
import { Address } from './address.model';
import { Taxpayer } from './taxpayer.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IPayment {
  invoice: Pago;
  payer: Payer;
  address: Address;
  taxpayer: Taxpayer;
}

export class Payment implements IPayment {
  public invoice: Pago;
  public payer: Payer;
  public address: Address;
  taxpayer: Taxpayer;

  constructor(payment?: IPayment ) {
    this.invoice = payment && payment.invoice || new Pago();
    this.payer = payment && payment.payer || new Payer();
    this.address = payment && payment.address || new Address();
    this.taxpayer = payment && payment.taxpayer || new Taxpayer();
  }
}

export const initialPaymentState: IPayment = new Payment();

export interface IPaymentMethod {
  method: string;
  icon: IconProp;
  label: string;
  priority: number;
}
