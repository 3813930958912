import { Component, Input } from '@angular/core';
import { faLock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-custom-logo',
  template: `
    <div class="payment-izi-logo d-flex" [ngClass]="classes">
      <img src="../../../../assets/images/izi-logo.png" alt="iZi" class="brand" />
      <div class="payment-link text-uppercase fw-bold border border-dark rounded-3 ms-1 d-flex">
        {{ "payment_title" | translate }} <fa-icon [icon]="faLock" class="ms-2 custom-fc-izi fs-7 lock"></fa-icon>
      </div>
    </div>`,
  styleUrls: ['./customLogo.component.scss']
})
export class CustomLogoComponent {
  @Input() small: boolean;

  faLock = faLock;

  constructor() {}

  get classes(): any {
    return {
      small: this.small
    };
  }
}
