import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { Constants } from '../../constants';
import { NavigatorStep } from '../../states/navigator.state';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { UserService } from '../../services/user.service';
import { NavigatorService } from '../../services/navigator.service';
import { UPDATE_USER } from '../../reducers/user.reducer';
import { UPDATE_INVOICE_FIELD_IF_EMPTY } from '../../reducers/invoice.reducer';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  constructor(private loginService: LoginService,
              private store: Store<AppState>,
              private userService: UserService,
              private navigator: NavigatorService,
              private formBuilder: FormBuilder,
              private toastService: ToastService) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f(): any {
    return this.loginForm.controls;
  }

  async onSubmit(): Promise<any> {
    try {
      const user = await this.loginService.login(this.loginForm.value);

      localStorage.setItem(Constants.LOCAL_STORAGE_USER_ID, user.usuario.id);
      localStorage.setItem(Constants.LOCAL_STORAGE_TOKEN, user.token);

      this.store.dispatch({
        type: UPDATE_USER,
        payload: {
          id: user.usuario.id,
          email: user.usuario.correoElectronico,
          status: user.usuario.estado,
          firstname: user.usuario.nombres,
          lastname: user.usuario.apPaterno
        }
      });

      const addresses = await this.userService.getAddresses(user.usuario.id, user.token);
      this.store.dispatch({
        type: 'UPDATE_USER_ADDRESS',
        payload: {
          addresses
        }
      });

      this.store.dispatch({
        type: UPDATE_INVOICE_FIELD_IF_EMPTY,
        payload: {
          key: 'EMAIL',
          priority: 1,
          field: {
            value: user.usuario.correoElectronico
          }
        }
      });

      this.goToInvoice();
    } catch (e) {
      let errorMessage;
      try {
        const message = JSON.parse(e.message);
        errorMessage = message && message.error ? message.error : message;
      } catch (e1) {
        errorMessage = e.message;
      }
      console.log(errorMessage);
      this.toastService.show(`Ha ocurrido un error al intentar iniciar sesion: </br> ${errorMessage}`, { classname: 'bg-danger' });
    }
  }

  goToInvoice(): void {
    this.navigator.go(NavigatorStep.invoice);
  }

  goToRegister(): void {
    this.navigator.go(NavigatorStep.register);
  }

  goToForgot(): void {
    this.navigator.go(NavigatorStep.forgot);
  }
}
