import { loaderReducer } from './loader.reducer';
import { navigatorReducer } from './navigator.reducer';
import { paymentReducer } from './payment.reducer';
import { userReducer } from './user.reducer';
import { orderReducer } from './order.reducer';
import { invoiceReducer } from './invoice.reducer';
import { deliveryReducer } from './delivery.reducer';

export const reducers = {
  loader: loaderReducer,
  navigator: navigatorReducer,
  payment: paymentReducer,
  user: userReducer,
  order: orderReducer,
  invoice: invoiceReducer,
  delivery: deliveryReducer
};
