import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { Loader } from './states/loader.state';
import { AppState } from './app.state';

import { UserService } from './services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './services/loader.service';

import { UPDATE_USER, UPDATE_USER_ADDRESS } from './reducers/user.reducer';

import StorageUtil from './utils/storage.util';
import { environment } from '../environments/environment';
import { ScriptService } from './services/script.service';

import { NGXLogger } from 'ngx-logger';
import { UPDATE_INVOICE_FIELD_IF_EMPTY } from './reducers/invoice.reducer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loader$: Observable<Loader>;

  constructor(private store: Store<AppState>,
              private userService: UserService,
              private translate: TranslateService,
              private loader: LoaderService,
              private scriptService: ScriptService,
              private logger: NGXLogger
  ) {
    this.defineTranslateLanguage();
    this.defineObservables();
    this.showLoader();
  }

  defineTranslateLanguage(): void {
    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang('es');
  }

  defineObservables(): void {
    this.loader$ = this.store.select(state => state.loader);
  }

  async ngOnInit(): Promise<any> {
    const { userId, token } = StorageUtil.getStoredParams();

    if (environment.enableAutoLogin && userId && token) {
      try {
        const user = await this.userService.getUserData(userId, token);
        this.store.dispatch({
          type: UPDATE_USER,
          payload: {
            id: user.id,
            uuid: user.uuid,
            email: user.correoElectronico,
            status: user.estado,
            firstname: user.nombres,
            lastname: user.apPaterno
          }
        });

        if (environment.steps.addressEnabled) {
          const addresses = await this.userService.getAddresses(userId, token);
          this.store.dispatch({
            type: UPDATE_USER_ADDRESS,
            payload: {
              addresses
            }
          });
        }

        this.store.dispatch({
          type: UPDATE_INVOICE_FIELD_IF_EMPTY,
          payload: {
            key: 'EMAIL',
            priority: 1,
            field: {
              value: user.correoElectronico
            }
          }
        });
      } catch (e) {
        this.logger.error(e);
        StorageUtil.removeStoredParams();
      }
    } else {
      StorageUtil.removeStoredParams();
    }
  }

  showLoader(): void {
    this.loader.open();
  }
}
