import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpTranslateLoader } from './loaders/translate.loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { NgxMaskModule } from 'ngx-mask';
import { CoreModule } from './core/core.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/common/header/header.component';
import { LoaderComponent } from './components/common/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { RegisterComponent } from './components/register/register.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentSuccessComponent } from './components/payment/payment-success/payment-success.component';
import { PaymentFailedComponent } from './components/payment/payment-failed/payment-failed.component';
import { ForgotPasswordComponent } from './components/forgotPassword/forgotPassword.component';
import { ResetPasswordComponent } from './components/resetPassword/resetPassword.component';
import { ErrorComponent } from './components/error/error.component';
import { ToastComponent } from './components/common/toast/toast.component';
import { PaymentQrComponent } from './components/payment/payment-qr/payment-qr.component';
import { PaymentCardComponent } from './components/payment/payment-card/payment-card.component';
import { PaymentSummaryComponent } from './components/payment/payment-summary/paymentSummary.component';
import { CustomPanelComponent } from './components/common/customPanel/customPanel.component';
import { CustomLogoComponent } from './components/common/customLogo/customLogo.component';
import { PaymentMethodComponent } from './components/payment/payment-method/payment-method.component';
import { ChangePasswordComponent } from './components/changePassword/changePassword.component';

import { UTF8DecodePipe } from './pipes/utf8.pipe'

import { environment } from '../environments/environment';
import { reducers } from './reducers';

const config: SocketIoConfig = { url: environment.apiNotificationURL + '/payment_notification', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    LoginComponent,
    InvoiceComponent,
    DeliveryComponent,
    HomeComponent,
    RegisterComponent,
    ScheduleComponent,
    PaymentComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ErrorComponent,
    ToastComponent,
    PaymentQrComponent,
    PaymentCardComponent,
    PaymentSummaryComponent,
    CustomPanelComponent,
    CustomLogoComponent,
    PaymentMethodComponent,
    ChangePasswordComponent,
    UTF8DecodePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    CoreModule,
    FontAwesomeModule,
    StoreModule.forRoot(
      reducers,
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: false
        }
      }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    }),
    NgxMaskModule.forRoot(),
    AccordionModule.forRoot(),
    SocketIoModule.forRoot(config),
    TooltipModule.forRoot(),
    LoggerModule.forRoot({serverLoggingUrl: '', level: environment.logLevel, serverLogLevel: NgxLoggerLevel.OFF, disableFileDetails: true})
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
