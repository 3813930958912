<div class="error-wrapper w-100">
  <app-custom-panel [defaultWidth]="true" class="text-center">
    <ng-container *ngIf="type !== 'info'">
      <img src="assets/images/izi-img-alert-warning.svg" alt="" />
      <!-- <fa-icon [icon]="faInfoCircle" [ngClass]="type"></fa-icon> -->
    </ng-container>

    <ng-container *ngIf="type === 'info'">
      <div class="title--medium--dark">
        {{ "error_page.info.title" | translate }}
      </div>

      <div class="body--darkgrey">
        {{ "error_page.info.description" | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="type === 'invalid'">
      <div class="title--medium--dark mt-4 mb-3">
        {{ "error_page.invalid.title" | translate }}
      </div>

      <div class="body--darkgrey mb-4">
        {{ "error_page.invalid.description" | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="type === 'completed'">
      <div class="fw-bold py-2">
        {{ "error_page.completed.title" | translate }}
      </div>

      <div class="">{{ "error_page.completed.description" | translate }}</div>
    </ng-container>
  </app-custom-panel>
</div>
