export enum NavigatorStep {
  login = 'LOGIN',
  register = 'REGISTER',
  forgot = 'FORGOT_PASSWORD',
  reset = 'RESET_PASSWORD',
  invoice = 'INVOICE',
  delivery = 'DELIVERY',
  schedule = 'SCHEDULE',
  payment = 'PAYMENT',
  paymentAccepted = 'PAYMENT_ACCEPTED',
  paymentFailed = 'PAYMENT_FAILED',
  error = 'error',
  empty = 'empty'
}

export interface NavigatorState {
  step: NavigatorStep;
  steps: Array<string>;
}

export const initialNavigatorState: NavigatorState = {
  step: NavigatorStep.empty,
  steps: []
};
