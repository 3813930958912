import { Injectable } from '@angular/core';
import HttpUtil from '../utils/http.util';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  private httpUtil;

  constructor() {
    this.httpUtil = HttpUtil.Instance;
  }

  public getCountryList(): Promise<any> {
    return this.httpUtil.execute({
      url: `/paises/`
    });
  }

  public getCities(country: string): Promise<any> {
    return this.httpUtil.execute({
      url: `/paises/${country}/estados`
    });
  }

  public getCity(city: string, country: string): Promise<any> {
    return this.httpUtil.execute({
      url: `/micro-pagos/paises/${country}/estado/${city}`
    });
  }
}
