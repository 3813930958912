import { Injectable } from '@angular/core';
import HttpUtil from '../utils/http.util';
import { Credentials } from '../core/models/credenciales.model';
import CryptoUtil from '../utils/crypto.util';
import DeviceUtil from '../utils/device.util';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private httpUtil;

  constructor() {
    this.httpUtil = HttpUtil.Instance;
  }

  public login(credentials: Credentials): Promise<any> {
    return this.httpUtil.execute({
      url: `/login`,
      method: 'POST',
      data: {
        correoElectronico: credentials.username,
        contrasena: credentials.password,
        cadena: DeviceUtil.getUserAgent()
      },
      headers: {
        Authorization: this.getBasicAuth(credentials)
      }
    });
  }

  public forgotPassword(email: string): Promise<any> {
    return this.httpUtil.execute({
      url: `/lost-password`,
      method: 'POST',
      data: {
        correoElectronico: email
      }
    });
  }

  getBasicAuth(credentials: Credentials): string {
    return 'Basic ' + CryptoUtil.encode(`${credentials.username}:${credentials.password}`);
  }
}
