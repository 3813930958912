import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { IOrder } from '../../../core/models/order.model';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import DataUtil from '../../../utils/data.util';
import { faCreditCard, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { IPaymentMethod } from '../../../core/models/payment.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
const ID_PAYMENT_METHOD_CARD =2;
const ID_PAYMENT_METHOD_QR =3;

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

  order$: Observable<IOrder>;

  selectedPaymentMethod: string;

  paymentMethodOptions: Array<IPaymentMethod>;

  @Output() paymentMethodSelected = new EventEmitter<string>();

  constructor(private store: Store<AppState>) {
    this.order$ = this.store.select(state => state.order);
  }

  async ngOnInit(): Promise<void> {
    this.paymentMethodOptions = [];
    this.order$.pipe(take(1)).subscribe((data: IOrder) => this.updatePaymentMethodOptions(data));
  }

  async selectPaymentMethod(event, value: any): Promise<void> {
    this.selectedPaymentMethod = DataUtil.getValue(value);
    this.paymentMethodSelected.emit(this.selectedPaymentMethod);
    event.preventDefault();
  }

  updatePaymentMethodOptions(paymentMethods: IOrder): void {
    this.paymentMethodSelected.emit(this.selectedPaymentMethod);
    if(paymentMethods.paymentMethods.includes(ID_PAYMENT_METHOD_CARD)){
      this.paymentMethodOptions.push({
        priority: 1,
        method: 'card',
        label: 'payment.options.card',
        icon: faCreditCard
      });
    }
    if(paymentMethods.paymentMethods.includes(ID_PAYMENT_METHOD_QR)){
      this.paymentMethodOptions.push({
        priority: 2,
        method: 'qr',
        label: 'payment.options.qr',
        icon: faQrcode
      });
    }
  
    this.selectedPaymentMethod = _.get(this.paymentMethodOptions, ['0', 'method']);

    this.paymentMethodSelected.emit(this.selectedPaymentMethod);
  }
}

