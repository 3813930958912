import {
  Component,
  OnInit,
  KeyValueChanges,
  KeyValueDiffer,
  KeyValueDiffers,
  DoCheck,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Observable } from 'rxjs';
import { IUser } from '../../../core/models/user.model';
import { CityService } from '../../../services/city.service';
import { Card } from '../../../core/models/card.model';
import { ActivatedRoute } from '@angular/router';
import DataUtil from '../../../utils/data.util';
import CardTypeUtil from '../../../utils/cardType.util';
import * as _ from 'lodash';

import { faQuestionCircle, faCreditCard, faLock, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCcMastercard, faCcVisa, faCcAmex, faCcDiscover, faCcDinersClub, faCcJcb } from '@fortawesome/free-brands-svg-icons';
import { LoaderService } from '../../../services/loader.service';
import { IDelivery } from '../../../core/models/delivery.model';
import { take } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent implements OnInit, DoCheck {
  faQuestionCircle = faQuestionCircle;
  faCreditCard = faCreditCard;
  faLock = faLock;
  faTrashAlt = faTrashAlt;
  faPlus = faPlus;

  CardTypeUtil = CardTypeUtil;

  user$: Observable<IUser>;

  delivery$: Observable<IDelivery>;

  countryList: Array<any>;

  cityList: Array<any>;

  selectedCardType: string;

  showCcvInstructions: boolean;

  private cardDiffer: KeyValueDiffer<string, any>;

  card: Card;

  private defaultCountry = 'BO';

  private defaultCity: string;

  private defaultAddress: string;

  @ViewChild('paymentForm') paymentForm: NgForm;

  @ViewChild('paymentCCVForm') paymentCCVForm: NgForm;

  @Output() cardUpdated = new EventEmitter<Card>();

  @Output() cardRemoved = new EventEmitter<Card>();

  constructor(private route: ActivatedRoute,
              private store: Store<AppState>,
              private cityService: CityService,
              private differs: KeyValueDiffers,
              private loader: LoaderService) {
    this.user$ = this.store.select(state => state.user);
    this.delivery$ = this.store.select(state => state.delivery);
  }

  async ngOnInit(): Promise<void> {
    await this.delivery$.pipe(take(1)).subscribe((data) => this.updateDefaultAddressData(data));
    this.createNewCard();
    this.cardDiffer = this.differs.find(this.card).create();
    this.selectedCardType = 'debit';
    this.showCcvInstructions = false;
    this.countryList = await this.cityService.getCountryList();
    await this.setDefaultCardValues();
    this.user$.subscribe(async (data: IUser) => await this.updateCardList(data));
  }

  ngDoCheck(): void {
    if (this.cardDiffer) {
      const changes = this.cardDiffer.diff(this.card);
      if (changes) {
        this.cardChanged(changes);
      }
    }
  }

  cardChanged(changes: KeyValueChanges<string, any>): void {
    this.card.type = CardTypeUtil.getCardType(this.card.cardNumber);
    this.cardUpdated.emit(this.card);
  }

  async updateCardList(data): Promise<void> {
    if (data && data.cards && data.cards.length > 0) {
      this.selectCard(data.cards[0]);
    } else {
      await this.addNewCard();
    }
    this.loader.close();
  }

  async updateCountry(event: any): Promise<void> {
    await this.getCityList(event.target.value);
  }

  async getCityList(countryCode: string): Promise<void> {
    this.cityList = await this.cityService.getCities(countryCode);
  }

  parseSelectorOptions(data: string): Array<any> {
    return data.split(',');
  }

  selectCardType(value: any): void {
    this.selectedCardType = DataUtil.getValue(value);
    this.card.cardType = DataUtil.getValue(value);
  }

  toggleCCVInstructions(): void {
    this.showCcvInstructions = !this.showCcvInstructions;
  }

  getCardTypeIcon(type): any {
    switch (type) {
      case 'mastercard':
        return faCcMastercard;
      case 'visa':
        return faCcVisa;
      case 'amex':
        return faCcAmex;
      case 'diners':
        return faCcDinersClub;
      case 'discover':
        return faCcDiscover;
      case 'jcb':
        return faCcJcb;
      default:
        return faCreditCard;
    }
  }

  selectCard(card: any): void {
    this.card = _.clone(card);
  }

  deleteCard(event: any, card: Card): void {
    this.cardRemoved.emit(card);
    event.preventDefault();
  }

  async addNewCard(): Promise<void> {
    this.createNewCard();
    await this.setDefaultCardValues();
  }

  createNewCard(): void {
    this.card = new Card();
  }

  async setDefaultCardValues(): Promise<void> {
    this.card.cardType = this.selectedCardType;
    if (this.defaultCountry) {
      this.card.country = this.defaultCountry;
      await this.getCityList(this.card.country);
    }
    if (this.defaultCity) {
      this.card.city = this.defaultCity;
    }
    if (this.defaultAddress) {
      this.card.address = this.defaultAddress;
    }
  }

  async updateDefaultAddressData(data: IDelivery): Promise<void> {
    if (data.address?.country && !DataUtil.isEmpty(data.address.country)) {
      this.defaultCountry = data.address.country;
    }
    if (data.address?.city  && !DataUtil.isEmpty(data.address.city)) {
      this.defaultCity = data.address.city;
    }
    if (data.address?.address &&  !DataUtil.isEmpty(data.address.address)) {
      this.defaultAddress = data.address.address;
    }
  }

  validateCardForm(): void {
    if (this.paymentForm) {
      this.paymentForm.onSubmit(undefined);
    } else if (this.paymentCCVForm) {
      this.paymentCCVForm.onSubmit(undefined);
    }
  }
}

