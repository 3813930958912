<div class="app-schedule">
  <app-custom-panel [defaultLayout]="true" [defaultBackground]="false" [border]="false" [defaultWidth]="true">
    <ng-container *ngIf="delivery$ && (delivery$ | async)">
      <div class="pt-3 fs-5">{{'schedule.title' | translate }}</div>

      <div class="border-bottom">
        <div class="d-flex bd-highlight align-items-center">
          <div class="p-2 bd-highlight">
            <fa-icon class="fa-izi pe-2" [icon]="icon.faMapMarkerAlt"></fa-icon>
            <span class="fw-bold">{{'schedule.address' | translate : { address: (delivery$ | async).address.address, city: cityName } }}</span>
          </div>
          <div class="ms-auto p-2 bd-highlight">
            <button type="button"
                    class="btn btn-link fw-bold"
                    (click)="goToAddressSelector()">
              {{ "common.change" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="pt-3 fs-5">{{'schedule.delivery_date' | translate }}</div>

      <div class="izi-highlighted-text fw-bold text-capitalize">{{ formatDate(date) }}</div>

      <div class="pt-3 fs-5">{{ 'schedule.delivery_time' | translate }}</div>

      <form #formSchedule="ngForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <div class="mb-3 form-check custom-radio-button-wrapper" [ngClass]="{ checked: selectedPeriod === 'morning' }" (click)="selectPaymentMethod('morning')">
            <input class="form-check-input" type="radio" name="daytime" id="by_morning" value="morning" checked [checked]="selectedPeriod === 'morning'" (change)="selectPaymentMethod($event.target)">
            <label class="form-check-label" for="by_morning">
              {{ 'schedule.by_morning' | translate }} <span class="text-black-50">{{ 'schedule.by_morning_schedule' | translate }}</span>
            </label>
          </div>
          <div class="mb-3 form-check custom-radio-button-wrapper" [ngClass]="{ checked: selectedPeriod === 'noon' }" (click)="selectPaymentMethod('noon')">
            <input class="form-check-input" type="radio" name="daytime" id="by_noon" value="noon" [checked]="selectedPeriod === 'noon'" (change)="selectPaymentMethod($event.target)">
            <label class="form-check-label" for="by_noon">
              {{ 'schedule.by_noon' | translate }} <span class="text-black-50">{{ 'schedule.by_noon_schedule' | translate }}</span>
            </label>
          </div>
          <div class="mb-3 form-check custom-radio-button-wrapper" [ngClass]="{ checked: selectedPeriod === 'afternoon' }" (click)="selectPaymentMethod('afternoon')">
            <input class="form-check-input" type="radio" name="daytime" id="by_afternoon" value="afternoon" [checked]="selectedPeriod === 'afternoon'" (change)="selectPaymentMethod($event.target)">
            <label class="form-check-label" for="by_afternoon">
              {{ 'schedule.by_afternoon' | translate }} <span class="text-black-50">{{ 'schedule.by_afternoon_schedule' | translate }}</span>
            </label>
          </div>
        </div>

        <div class="d-grid gap-2 col-6 mx-auto py-4">
          <button type="submit"
                  class="btn btn-primary text-uppercase"
                  [disabled]="!formSchedule.form.valid">
            {{ 'common.next' | translate }}
          </button>
        </div>
      </form>
    </ng-container>
  </app-custom-panel>
</div>
