import { Address } from './address.model';
import * as _ from 'lodash';
import { Card, ICard } from './card.model';


export const QHANTUY = 'QHANTUY';

export interface IUser {
  id: number;
  uuid: string;
  firstname: string;
  lastname: string;
  email: string;
  status: number;
  addresses: Array<Address>;
  cards: Array<Card>;
}

export class User implements IUser {
  public id: number;
  public uuid: string;
  public email: string;
  public firstname: string;
  public lastname: string;
  public status: number;
  public addresses: Array<Address>;
  public cards: Array<Card>;

  constructor(record?: IUser ) {
    this.id = record && record.id || 0;
    this.uuid = record && record.uuid || null;
    this.email = record && record.email || '';
    this.firstname = record && record.firstname || '';
    this.lastname = record && record.lastname || '';
    this.status = record && record.status || 0;
    this.addresses =  record && record.addresses || [];
    this.cards = record && record.cards || [];
  }
}

export const initialUserState: IUser = new User();

export const parseUserAddresses = (items) => {
  return _.map(items, (item) => (
    new Address({
      id: _.get(item, 'id'),
      alias: _.get(item, 'alias'),
      country: _.get(item, 'pais'),
      city: _.get(item, 'ciudad'),
      address: _.get(item, 'direccion'),
      latitude: _.get(item, 'latitud'),
      longitude: _.get(item, 'longitud'),
      description: _.get(item, 'descripcion'),
      save: true,
    } as Address)
  ));
};

export const parseUserCards = (items) => {
  return _.map(items, (item,key) => {
    if(_.get(item,"pasarela")==QHANTUY){
      let dateItem=_.get(item, 'date',"122000");
      let dateString=dateItem.length==6?dateItem.substring(0,2)+"/"+dateItem.substring(2,6):dateItem;
      return new Card({
        cardType: "---",
        country: "BO",
        city: "L",
        name: "---",
        cardNumber: _.get(item, 'tarjeta'),
        expirationDate: dateString,
        id: "tj-"+key,
        address: "",
        token: _.get(item,'token')
      } as ICard);
    }
    return new Card({
      cardType: _.get(item, 'tipo'),
      country: _.get(item, 'pais'),
      city: _.get(item, 'estado'),
      name: _.get(item, 'issuedTo'),
      cardNumber: _.get(item, 'numero'),
      expirationDate: _.get(item, 'expirationDate'),
      id: _.get(item, 'id'),
      address:  _.get(item, 'direccion')
    } as ICard);
  });
};
