import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { NavigatorService } from '../../services/navigator.service';
import { RegisterService } from '../../services/register.service';
import { ToastService } from '../../services/toast.service';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../services/user.service';
import StorageUtil from '../../utils/storage.util';
import { NGXLogger } from 'ngx-logger';
import { MustMatch } from '../../validators/must-match.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  faEyeSlash = faEyeSlash;

  faEye = faEye;

  public showOldPassword: boolean;

  public showNewPassword: boolean;

  public showConfirmPassword: boolean;

  changePasswordForm: FormGroup;

  @Output() changeCompleted = new EventEmitter<boolean>();

  @Output() changeFailed = new EventEmitter<boolean>();

  constructor(private registerService: RegisterService,
              private loginService: LoginService,
              private navigator: NavigatorService,
              private toastService: ToastService,
              private userService: UserService,
              private logger: NGXLogger,
              private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.showOldPassword = false;
    this.showNewPassword = false;
    this.showConfirmPassword = false;

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
  }

  get f(): any {
    return this.changePasswordForm.controls;
  }

  async onSubmit(): Promise<any> {
    try {
      const { userId, token } = StorageUtil.getStoredParams();

      await this.userService.updateUserPassword(userId, token, this.changePasswordForm.value);
      this.changeCompleted.emit(true);
    } catch (e) {
      this.logger.error(e);
      this.changeFailed.emit(false);
    }
  }

  toggleShowOldPassword(): void {
    this.showOldPassword = !this.showOldPassword;
  }

  toggleShowNewPassword(): void {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  cancel(): void {
    this.changeFailed.emit(true);
  }
}
