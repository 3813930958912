import { Component, OnInit ,Input} from '@angular/core';
import { PaymentService } from '../../../services/payment.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IInvoice,Invoice} from '../../../core/models/invoice.model';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-payment-qr',
  templateUrl: './payment-qr.component.html',
  styleUrls: ['./payment-qr.component.scss']
})
export class PaymentQrComponent implements OnInit {
  paymentId: string;
  @Input()  qrData: string;
  @Input()  waiting: boolean;

  faLock = faLock;

  
  invoice$: Observable<IInvoice>;

  constructor(private route: ActivatedRoute,
              private paymentService: PaymentService,
              private store: Store<AppState>) {
                this.invoice$ = this.store.select(state => state.invoice);}

  async ngOnInit(): Promise<void> {
  }


  
  downloadQR() {
    var nombreArchivo = this.qrData.substring(this.qrData.lastIndexOf('/') + 1);

    var link = document.createElement('a');
    link.href = this.qrData;
    link.download = nombreArchivo;
    link.target = "_blank"; 

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
}
}

