import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Observable } from 'rxjs';
import { NavigatorState, NavigatorStep } from '../../../states/navigator.state';
import { TranslateService } from '@ngx-translate/core';
import { Taxpayer } from '../../../core/models/taxpayer.model';
import { environment } from '../../../../environments/environment';
import { IPayment } from '../../../core/models/payment.model';
import * as _ from 'lodash';
import { NavigatorService } from '../../../services/navigator.service';
import { IUser } from '../../../core/models/user.model';
import { REMOVE_USER } from '../../../reducers/user.reducer';
import StorageUtil from '../../../utils/storage.util';
import { faLock, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  displayPageTitle = false;
  displayMainBrandLogo = false;
  displayPaymentSiteLogo = true;
  displayBackLink = false;
  pageTitle: string;
  displayBrandLogo = false;
  displaySeparator = false;

  navigator$: Observable<NavigatorState>;

  taxpayer: Taxpayer;

  baseURL = environment.apiURL;

  payment$: Observable<IPayment>;

  user$: Observable<IUser>;

  faLock = faLock;
  faChevronLeft = faChevronLeft;

  constructor(private router: Router,
              private store: Store<AppState>,
              private translate: TranslateService,
              private navigator: NavigatorService,
              private modalService: NgbModal) {
    this.navigator$ = this.store.select(state => state.navigator);
    this.navigator$.subscribe((data) => this.updateHeader(data));

    this.taxpayer = new Taxpayer();
    this.payment$ = this.store.select(state => state.payment);
    this.payment$.subscribe((data) => this.updatePaymentForm(data));

    this.user$ = this.store.select(state => state.user);
  }

  updatePaymentForm(data: IPayment): void {
    this.taxpayer = _.cloneDeep(data.taxpayer);
  }

  updateHeader(data: NavigatorState): void {
    const { step } = data;

    switch (step) {
      case NavigatorStep.login:
      case NavigatorStep.register:
        this.displayMainBrandLogo = true;
        this.displayBackLink = true;
        this.displayPageTitle = false;
        this.displayPaymentSiteLogo = false;
        this.displayBrandLogo = false;
        this.displaySeparator = false;
        break;
      case NavigatorStep.invoice:
        this.displayMainBrandLogo = false;
        this.displayBackLink = false;
        this.displayPageTitle = false;
        this.displayPaymentSiteLogo = true;
        this.displayBrandLogo = false;
        this.displaySeparator = false;
        break;
      case NavigatorStep.delivery:
        this.displayMainBrandLogo = false;
        this.displayBackLink = false;
        this.displayPageTitle = true;
        this.displayPaymentSiteLogo = true;
        this.pageTitle = this.translate.instant('delivery.header');
        this.displayBrandLogo = true;
        this.displaySeparator = true;
        break;
      case NavigatorStep.schedule:
        this.displayMainBrandLogo = false;
        this.displayBackLink = false;
        this.displayPageTitle = false;
        this.displayPaymentSiteLogo = true;
        this.displayBrandLogo = true;
        this.displaySeparator = true;
        break;
      case NavigatorStep.payment:
        this.displayMainBrandLogo = false;
        this.displayBackLink = false;
        this.displayPageTitle = false;
        this.displayPaymentSiteLogo = true;
        this.displayBrandLogo = true;
        this.displaySeparator = true;
        break;
      default:
        break;
    }
  }

  goToInvoice(): void {
    this.navigator.go(NavigatorStep.invoice);
  }

  getName(user: IUser): string {
    return `${user.firstname} ${user.lastname}`;
  }

  openChangePasswordModal(modal: any): void {
    this.open(modal);
  }

  open(content): void {
    this.modalService.open(content).result.then(() => {});
  }

  logout(): void {
    StorageUtil.removeStoredParams();

    this.store.dispatch({
      type: REMOVE_USER
    });
  }

  onChangeCompleted(event, modal): void {
    if (!!event) {
      modal.close();
    }
  }

  onChangeFailed(event, modal): void {
    if (!!event) {
      modal.close();
    }
  }

  back(): void {
    this.navigator.back();
  }
}
