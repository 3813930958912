import { Injectable } from '@angular/core';
import HttpUtil from '../utils/http.util';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private httpUtil;

  constructor() {
    this.httpUtil = HttpUtil.Instance;
  }

  public getPaymentData(paymentId: string): Promise<any> {
    return this.httpUtil.execute({
      url: `/solicitudes-cobro/buscar/${paymentId}`,
      method: 'GET'
    });
  }

  public executePayment(data: any): Promise<any> {
    return this.httpUtil.execute({
      url: `/solicitudes-cobro/intento-pago`,
      method: 'POST',
      data
    });
  }

  public getDeliveryDate(paymentId: string, data: any): Promise<any> {
    return this.httpUtil.execute({
      url: `/micro-pagos/cobro/${paymentId}/entrega`,
      method: 'POST',
      data
    });
  }

  // public executePayment(paymentId: string, data: any): Promise<any> {
  //   return this.httpUtil.execute({
  //     url: `/micro-pagos/cobro/${paymentId}/pago`,
  //     method: 'POST',
  //     data
  //   });
  // }

  public validatePayment(paymentId: string, data: any): Promise<any> {
    return this.httpUtil.execute({
      url: `/micro-pagos/cobro/${paymentId}/validar`,
      method: 'POST',
      data
    });
  }

  public getSecureToken(paymentId: string): Promise<any> {
    return this.httpUtil.execute({
      url: `/micro-pagos/cobro/${paymentId}/token`
    });
  }
}
