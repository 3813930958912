export class Item {
  public id: number;
  public name: string;
  public quantity: number;
  public price: number;
  public totalPrice: number;

  constructor(id, name, quantity, price, totalPrice ) {
    this.id = id || 0;
    this.name = name || '';
    this.quantity = quantity || 0;
    this.price = price || 0;
    this.totalPrice = totalPrice || 0;
  }
}
