import { Item } from './item.model';

import * as _ from 'lodash';

export interface IOrder {
  items: Array<Item>;
  subtotal: number;
  delivery: number;
  total: number;
  partial: number;
  currency: string;
  paymentMethods: Array<any>;
  sessionId: string;
  invoice: boolean;
  notificationUrl: string;
  config: any;
}

export class Order implements IOrder {
  public items: Array<Item>;
  public subtotal: number;
  public delivery: number;
  public total: number;
  public partial: number;
  public currency: string;
  public paymentMethods: Array<any>;
  public sessionId: string;
  public invoice: boolean;
  public notificationUrl: string;
  public config: any;

  constructor(order?: IOrder) {
    this.items = order && order.items || [];
    this.subtotal = order && order.subtotal || 0;
    this.delivery = order && order.delivery || 0;
    this.total = order && order.total || 0;
    this.partial = order && order.partial || undefined;
    this.currency = order && order.currency || '';
    this.paymentMethods = order && order.paymentMethods || [];
    this.sessionId = order && order.sessionId || '';
    this.invoice = order && order.invoice || false;
    this.notificationUrl = order && order.notificationUrl;
    this.config = order && order.config || {};
  }
}

export const initialOrderState: IOrder = new Order();

export const parseOrderItems = (originalItems) => {
  return _.map(originalItems, (item) => (
    new Item(
      0,
      _.get(item, 'articulo'),
      _.get(item, 'cantidad'),
      _.get(item, 'precioUnitario'),
      _.get(item, 'precioTotal'))
  ));
};
