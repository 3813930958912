import { Address } from './address.model';

export class Schedule {
  public date: string;
  public period: string;

  constructor(record?: Schedule) {
    this.date = record && record.date || '';
    this.period = record && record.period || '';
  }
}

export interface IDelivery {
  address: Address;
  schedule: Schedule;
}

export class Delivery implements IDelivery {
  public address: Address;
  public schedule: Schedule;

  constructor(record?: IDelivery) {
    this.address = record && record.address || new Address();
    this.schedule = record && record.schedule || new Schedule();
  }
}

export const initialDeliveryState: IDelivery = new Delivery();
