import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { NavigatorStep } from '../../states/navigator.state';
import { NavigatorService } from '../../services/navigator.service';
import { Register } from '../../core/models/register.model';
import { RegisterService } from '../../services/register.service';
import { ToastService } from '../../services/toast.service';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { NGXLogger } from 'ngx-logger';
import { MustMatch } from '../../validators/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  faEyeSlash = faEyeSlash;

  faEye = faEye;

  public register: Register;

  public showPassword: boolean;

  public showConfirmPassword: boolean;

  resetPasswordForm: FormGroup;

  constructor(private registerService: RegisterService,
              private loginService: LoginService,
              private navigator: NavigatorService,
              private toastService: ToastService,
              private logger: NGXLogger,
              private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.register = new Register();
    this.showPassword = false;
    this.showConfirmPassword = false;

    this.resetPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
      code: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f(): any {
    return this.resetPasswordForm.controls;
  }

  async onSubmit(): Promise<any> {
    await this.registerService.resetPassword(this.resetPasswordForm.value);
    this.goToLogin();
  }

  goToLogin(): void {
    this.navigator.go(NavigatorStep.login);
  }

  async resendCode(): Promise<void> {
    try {
      await this.loginService.forgotPassword(this.register.email);
    } catch (e) {
      this.logger.error(e);
      this.toastService.show(e, { classname: 'bg-danger' });
    }
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
