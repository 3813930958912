<app-custom-panel class="payment-qr pb-3" [border]="true">
  <div class="body--big-b--dark mb-3">{{ "payment.qr.title" | translate }}</div>

  <div class="body--darkgrey mb-2">
    <span class="body-m--primary me-1">1.</span>
    {{ "payment.qr.instruction_1" | translate }}
  </div>
  <div class="body--darkgrey mb-2">
    <span class="body-m--primary me-1">2.</span
    >{{ "payment.qr.instruction_2" | translate }}
  </div>
  <div class="body--darkgrey mb-2" 
  *ngIf="qrData">
    <span class="body-m--primary">3.</span>
    {{ "payment.qr.instruction_3" | translate }} <span class="ms-1"></span>
    {{ "payment.qr.instruction_4" | translate }}
    <a class="body-m--primary a-link--no-decoration" 
    (click)="downloadQR()">
      {{ "payment.qr.here" | translate }}
    </a>
  </div>

  <div class="body-b--darkgrey mt-3">
    {{ "payment.qr.are_you_ready" | translate }}
  </div>

  <div class="d-flex">
    <img
    class="border rounded-3 mt-4 w-100"
    *ngIf="qrData"
    src="{{qrData }}"
    alt=""
  />
  </div>
  <div class="mt-3 a-loader-spinner" *ngIf="waiting && !qrData"> </div>

  <div class="body-b--darkgrey mt-4">{{ "payment.qr.wait" | translate }}</div>

  <div class="d-flex mt-4 pb-0">
    <fa-icon [icon]="faLock" class="icon__grey"></fa-icon>
    <span class="ms-1 body--small--grey mt-1">{{
      "payment.ssl" | translate
    }}</span>
  </div>
</app-custom-panel>
