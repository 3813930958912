import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-panel',
  template: ` <div class="custom-panel" [ngClass]="classes">
    <ng-content></ng-content>
  </div>`,
  styleUrls: ['./customPanel.component.scss'],
})
export class CustomPanelComponent {
  @Input() defaultWidth: boolean;
  @Input() defaultBackground = true;
  @Input() defaultLayout = true;
  @Input() border = true;
  @Input() large: boolean;
  @Input() noMargin: boolean;
  @Input() noYPadding: boolean;

  constructor() {}

  get classes(): any {
    return {
      defaultBackground: this.defaultBackground,
      defaultLayout: this.defaultLayout,
      defaultWidth: this.defaultWidth,
      'izi-shadow': this.border,
      'rounded-8': this.border,
      large: this.large,
      'no-margin': this.noMargin,
      'py-0': this.noYPadding,
    };
  }
}
