export class Field {
  public id: number;
  public key: string;
  public keyAlt: string;
  public label: string;
  public shortLabel: string;
  public placeholder: string;
  public options: any;
  public value: string;
  public required: boolean;
  public type: string;
  public errors: object;
  public valueRaw: object;
  public readonly: boolean;

  constructor(id, key, keyAlt, label, shortLabel, placeholder, options, value, required, type, errors?,valueRaw?,readonly?) {
    this.id = id || 0;
    this.key = key || '';
    this.keyAlt = keyAlt || this.key.toLowerCase();
    this.label = label || '';
    this.shortLabel = shortLabel || label || '';
    this.placeholder = placeholder || '';
    this.options = options || '';
    this.value = value || '';
    this.required = required || false;
    this.type = type || '';
    this.errors = errors || {};
    this.valueRaw = valueRaw;
    this.readonly = readonly;
  }
}
