import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { NavigatorStep } from '../../states/navigator.state';
import { UserService } from '../../services/user.service';
import { NavigatorService } from '../../services/navigator.service';
import { ToastService } from '../../services/toast.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public email: string;

  constructor(private loginService: LoginService,
              private userService: UserService,
              private navigator: NavigatorService,
              private toastService: ToastService,
              private logger: NGXLogger
  ) { }

  ngOnInit(): void {}

  async onSubmit(): Promise<any> {
    try {
      await this.loginService.forgotPassword(this.email);
      this.goToRestorePassword();
    } catch (e) {
      this.logger.error(e);
      this.toastService.show(e, { classname: 'bg-danger' });
    }
  }

  goToRestorePassword(): void {
    this.navigator.go(NavigatorStep.reset);
  }

  goToLogin(): void {
    this.navigator.go(NavigatorStep.login);
  }
}
