import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IOrder } from '../../../core/models/order.model';
import { IInvoice, Invoice } from '../../../core/models/invoice.model';
import { REPLACE_INVOICE_FIELD } from '../../../reducers/invoice.reducer';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import DataUtil from '../../../utils/data.util';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './paymentSummary.component.html',
  styleUrls: ['./paymentSummary.component.scss']
})
export class PaymentSummaryComponent implements OnInit {
  order$: Observable<IOrder>;

  invoice$: Observable<IInvoice>;
  invoiceTmp$: BehaviorSubject<IInvoice> = new BehaviorSubject<IInvoice>(new Invoice());

  @Input() selectedPaymentMethod: string;

  @Output() executePaymentEvent = new EventEmitter<string>();
  @Output() resetQrEvent = new EventEmitter<string>();

  constructor(private route: ActivatedRoute,
              private store: Store<AppState>,
              private modalService: NgbModal) {
    this.order$ = this.store.select(state => state.order);
    this.invoice$ = this.store.select(state => state.invoice);
    this.store.select(state => this.invoiceTmp$.next(state.invoice));
  }

  async ngOnInit(): Promise<void> {
    this.invoice$.pipe(take(1)).subscribe((data: IInvoice) => this.updateInvoiceData(data));
  }

  async updateInvoiceData(record: IInvoice): Promise<void> {
    this.invoiceTmp$.next(record);
  }

  parseSelectorOptions(data: any): Array<any> {
    if(_.isObject(data)){
      return data as Array<any>;
    }
    return data.split(',');
  }

  open(content): void {
    this.modalService.open(content).result.then(() => {
      this.store.dispatch({
        type: REPLACE_INVOICE_FIELD,
        payload: this.invoiceTmp$.getValue()
      });
      this.resetQrEvent.emit();
    }, () => {
      this.invoice$.pipe(take(1)).subscribe((data) => this.updateInvoiceData(data));
    });
  }

  updateInvoiceFieldValue(key: string, value: any): void {
    const fields = _.map(this.invoiceTmp$.getValue().fields, (record) => {
      if (record.key === key) {
        return {
          ...record,
          value: DataUtil.getValue(value)
        };
      }
      return record;
    });
    this.invoiceTmp$.next({
      fields
    });
  }

  async executePayment(): Promise<void> {
    console.log("exectute payment");
    this.executePaymentEvent.emit();
  }
}

