import { Field } from './field.model';
import { DocTypeIdentity } from './docTypeIdentity.model';

import * as _ from 'lodash';

export interface IInvoice {
  fields: Array<Field>;
}

export class Invoice implements IInvoice {
  public fields: Array<Field>;

  constructor(record?: IInvoice) {
    this.fields = record && record.fields || Invoice.getInitialFields();
  }
  static getFieldDocumentType(documentTypes: Array<DocTypeIdentity>,initialvalue: string) {
    let options=[];
    for(let type of documentTypes){
      options.push({
        value: type.id,
        text: type.name
      })
    }
    return new Field(
      0,
      'DOCUMENT_TYPE',
      'tipoDocumento',
      'field.document_type.label',
      'field.document_type.short_label',
      'field.document_type.placeholder',
      options,
      initialvalue || '',
      true,
      'select',
      {
        required: 'field.document_type.error.required'
      }
    );
  }
  private static getInitialFields(): Array<Field> {
    return [
      new Field(
        0,
        'NIT',
        null,
        'field.nit.label',
        'field.nit.short_label',
        'field.nit.placeholder',
        '',
        '',
        true,
        'text',
        {
          required: 'field.nit.error.required'
        }
      ),
      new Field(
        0,
        'BUSINESS_NAME',
        'razonSocial',
        'field.business_name.label',
        'field.business_name.short_label',
        'field.business_name.placeholder',
        '',
        '',
        true,
        'text',
        {
          required: 'field.business_name.error.required'
        }
      ),
      new Field(
        0,
        'EMAIL',
        null,
        'field.email_invoice.label',
        'field.email_invoice.short_label',
        'field.email_invoice.placeholder',
        '',
        '',
        true,
        'text',
        {
          required: 'field.email_invoice.error.required'
        }
      )
    ];
  }
}

export const initialInvoiceState: IInvoice = new Invoice();

export const parseInvoiceFields = (records,values,isPrefactura) => {
  if(values){
    _.each(records,(record)=>{
      let valueFind=_.find(values,(value)=>{
        return value.id==record.id;
      })
      if(valueFind){
        record.valorPred=valueFind.valor;
      }
    });
  }
  console.log(records);
  console.log(values);
  return _.map(records, (record) => (
    new Field(
      0,
      _.get(record, 'nombre'),
      null,
      _.get(record, 'nombre'),
      null,
      _.get(record, 'nombre'),
      _.get(record, 'valor'),
      _.get(record, 'valorPred'),
      _.get(record, 'isObligatorio'),
      _.get(record, 'tipoCampo'),
      null,
      record,
      !isPrefactura,
      
      )
    ));
};
