<div class="payment-success w-100 mt-4">
  <app-custom-panel
    [defaultWidth]="true"
    class="payment-success-wrapper text-center"
  >
    <img
      src="assets/images/izi-img-sucess.svg"
      class="success-icon mx-auto"
      alt=""
    />

    <div class="title--medium--dark mt-3">
      {{ "payment_success.title" | translate }}
    </div>
    <div class="body--dark mt-2">
      {{ "payment_success.description" | translate }}
    </div>

    <div class="mx-auto mt-3 pb-3">
      <button
        type="button"
        
        (click)="goToHome()"
        class="a-izi-btn--medium a-izi-btn--medium--outline"
      >
        {{ "common.back_to_site" | translate }}
      </button>
    </div>
  </app-custom-panel>

  <app-custom-panel
    [defaultBackground]="false"
    [border]="false"
    class="text-center"
  >
    <div class="d-flex justify-content-center align-items-center">
      <div class="body--big-b--dark">
        {{ "payment_success.future_shops" | translate }}
      </div>
      <app-custom-logo [small]="true"></app-custom-logo>
    </div>
    <!-- <p class="body--darkgrey--85 mt-3">
      {{ "payment_success.advantages" | translate }}
      <a class="a-link body-m--primary" (click)="goToRelatedBusiness()">
        {{ "payment_success.related_business" | translate }}
      </a>
    </p> -->

    <div class="mx-auto mt-4">
      <button
        type="submit"
        class="a-izi-btn--medium a-izi-btn--medium--terciary"
        (click)="goToRegister()"
      >
        {{ "common.create_account" | translate }}
      </button>
    </div>
  </app-custom-panel>
</div>
