<div class="app-home">
  <app-header></app-header>

  <div class="home-content">
    <app-login
      *ngIf="navigator$ && (navigator$ | async).step === navigatorStep.login"
    ></app-login>

    <app-register
      *ngIf="navigator$ && (navigator$ | async).step === navigatorStep.register"
    ></app-register>

    <app-forgot-password
      *ngIf="navigator$ && (navigator$ | async).step === navigatorStep.forgot"
    ></app-forgot-password>

    <app-reset-password
      *ngIf="navigator$ && (navigator$ | async).step === navigatorStep.reset"
    ></app-reset-password>

    <app-invoice
      *ngIf="navigator$ && (navigator$ | async).step === navigatorStep.invoice"
    ></app-invoice>

    <app-delivery
      *ngIf="navigator$ && (navigator$ | async).step === navigatorStep.delivery"
    ></app-delivery>

    <app-schedule
      *ngIf="navigator$ && (navigator$ | async).step === navigatorStep.schedule"
    ></app-schedule>

    <app-payment
      *ngIf="navigator$ && (navigator$ | async).step === navigatorStep.payment"
    ></app-payment>

    <app-payment-success
      *ngIf="
        navigator$ &&
        (navigator$ | async).step === navigatorStep.paymentAccepted
      "
    ></app-payment-success>

    <app-payment-failed
      *ngIf="
        navigator$ && (navigator$ | async).step === navigatorStep.paymentFailed
      "
    ></app-payment-failed>

    <app-error
      *ngIf="navigator$ && (navigator$ | async).step === navigatorStep.error"
      [type]="errorType"
    ></app-error>
  </div>

  <!--  <app-footer></app-footer>-->
</div>
