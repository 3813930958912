<div class="footer">
  <select class="form-control"
          #selectedLanguage
          (change)="switchLanguage(selectedLanguage.value)">
    <option *ngFor="let language of translate.getLangs()"
            [value]="language"
            [selected]="language === translate.currentLang">
      {{ language }}
    </option>
  </select>
</div>
