<div class="app-login container">
  <div class="row align-items-center">
    <div class="col-md-7 col-sm-12">
      <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <label for="oldPassword" class="label--darkgrey--85">{{
            "field.current_password.label" | translate
          }}</label>
          <div class="input-group">
            <input
              [type]="showOldPassword ? 'text' : 'password'"
              class="a-input-form-group__input"
              formControlName="oldPassword"
              id="oldPassword"
              name="oldPassword"
              placeholder="{{
                'field.current_password.placeholder' | translate
              }}"
              required
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="toggleShowOldPassword()"
            >
              <fa-icon
                class="fa-izi"
                [icon]="showOldPassword ? faEyeSlash : faEye"
              ></fa-icon>
            </button>
          </div>
          <ng-container *ngIf="f.oldPassword.errors && f.oldPassword.touched">
            <span *ngIf="f.oldPassword.errors.required">
              {{ "field.current_password.error.invalid" | translate }}
            </span>
          </ng-container>
        </div>

        <div class="mb-3">
          <label for="newPassword" class="label--darkgrey--85">{{
            "field.new_password.label" | translate
          }}</label>
          <div class="input-group">
            <input
              [type]="showNewPassword ? 'text' : 'password'"
              class="form-control"
              formControlName="newPassword"
              id="newPassword"
              name="newPassword"
              placeholder="{{ 'field.new_password.placeholder' | translate }}"
              required
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="toggleShowNewPassword()"
            >
              <fa-icon
                class="fa-izi"
                [icon]="showNewPassword ? faEyeSlash : faEye"
              ></fa-icon>
            </button>
          </div>
          <ng-container *ngIf="f.newPassword.errors && f.newPassword.touched">
            <span *ngIf="f.newPassword.errors.required">
              {{ "field.new_password.error.invalid" | translate }}
            </span>
          </ng-container>
        </div>

        <div class="mb-3">
          <label for="confirmPassword" class="label--darkgrey--85">{{
            "field.confirm_new_password.label" | translate
          }}</label>
          <div class="input-group">
            <input
              [type]="showConfirmPassword ? 'text' : 'password'"
              class="form-control"
              formControlName="confirmPassword"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="{{
                'field.confirm_new_password.placeholder' | translate
              }}"
              required
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="toggleShowConfirmPassword()"
            >
              <fa-icon
                class="fa-izi"
                [icon]="showConfirmPassword ? faEyeSlash : faEye"
              ></fa-icon>
            </button>
          </div>
          <ng-container
            *ngIf="f.confirmPassword.errors && f.confirmPassword.touched"
          >
            <span *ngIf="f.confirmPassword.errors.required">
              {{ "field.confirm_new_password.error.invalid" | translate }}
            </span>
            <div *ngIf="f.confirmPassword.errors.mustMatch">
              {{ "field.confirm_password.error.must_match" | translate }}
            </div>
          </ng-container>
        </div>

        <div class="d-grid gap-2 col-9 mx-auto py-3">
          <button
            type="submit"
            class="btn btn-primary text-uppercase"
            [disabled]="!changePasswordForm.valid"
          >
            {{ "common.change_password" | translate }}
          </button>
        </div>

        <div class="centered-content py-2">
          <button type="button" class="btn btn-link fw-bold" (click)="cancel()">
            {{ "common.cancel" | translate }}
          </button>
        </div>
      </form>
    </div>
    <div class="col-md-5 col-sm-12">
      <img src="assets/images/reel1.svg" alt="" />
    </div>
  </div>
</div>
