import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default class DeviceUtil {
  static getUserAgent(): string {
    // Opera 8.0+
    // @ts-ignore
    const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    // @ts-ignore
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    // @ts-ignore
    // tslint:disable-next-line:max-line-length only-arrow-functions typedef
    const isSafari = /constructor/i.test(window.HTMLElement) || (function(p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification));

    // Internet Explorer 6-11
    // @ts-ignore
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    // @ts-ignore
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime || !!window.chrome.app);

    // Edge (based on chromium) detection
    const isEdgeChromium = isChrome && (navigator.userAgent.indexOf('Edg') !== -1);

    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    if (isOpera) { return 'Opera'; }
    if (isFirefox) { return 'Firefox'; }
    if (isSafari) { return 'Safari'; }
    if (isIE) { return 'IE'; }
    if (isEdge) { return 'Edge'; }
    if (isChrome) { return 'Chrome'; }
    if (isEdgeChromium) { return 'EdgeChromium'; }
    if (isBlink) { return 'Blink'; }

    return 'unknown';
  }

  static getHostname(): string {
    return window.location.hostname;
  }

  static async getDeviceFingerPrint(): Promise<any> {
    const fingerprintFn = await FingerprintJS.load();
    return await fingerprintFn.get();
  }

  static async getVisitorId(): Promise<string> {
    const deviceFingerprint = await this.getDeviceFingerPrint();
    return deviceFingerprint.visitorId;
  }

  static async getAdditionalFingerPrintDetails(): Promise<object> {
    const deviceFingerprint = await this.getDeviceFingerPrint();
    return {
      fonts: deviceFingerprint.components.fonts,
      languages: deviceFingerprint.components.languages,
      deviceMemory: deviceFingerprint.components.deviceMemory,
      screenResolution: deviceFingerprint.components.screenResolution,
      timezone: deviceFingerprint.components.timezone,
      platform: deviceFingerprint.components.platform,
      touchSupport: deviceFingerprint.components.touchSupport,
      vendor: deviceFingerprint.components.vendor,
      vendorFlavors: deviceFingerprint.components.vendorFlavors,
      colorDepth: deviceFingerprint.components.colorDepth
    };
  }

  static async getFingerPrint(): Promise<any> {
    const additionalDetails = await this.getAdditionalFingerPrintDetails();
    return {
      ...additionalDetails,
      userAgent: this.getUserAgent(),
      hostName: this.getHostname(),
      visitorId: await this.getVisitorId()
    };
  }
}
