<div class="payment-failed w-100">
  <app-custom-panel
    [defaultWidth]="true"
    class="payment-failed-wrapper text-center"
  >
    <!-- <fa-icon [icon]="faExclamationCircle" class="my-5"></fa-icon> -->
    <i class="izi-icon-alert error-icon"></i>
    <div class="title--medium--dark mt-3" style="text-transform: capitalize">
      {{ "payment_failed.title" | translate }}
    </div>
    <div class="body--darkgrey mt-2">
      {{ "payment_failed.description" | translate }}
    </div>
  </app-custom-panel>

  <div class="text-center title--dark pt-5">
    {{ "payment_failed.reasons" | translate }}
  </div>

  <div class="accordion-wrapper d-grid gap-2 col-10 col-md-8 mx-auto py-5">
    <accordion [isAnimated]="true" [closeOthers]="true">
      <accordion-group [panelClass]="customAccordionClass">
        <div class="w-100 d-flex fs-5 py-3" accordion-heading>
          <span class="flex-grow-1">{{
            "payment_failed.reason_title_1" | translate
          }}</span>
          <span class="badge badge-secondary"
            ><fa-icon [icon]="faPlusCircle"></fa-icon
          ></span>
        </div>
        {{ "payment_failed.reason_description_1" | translate }}
      </accordion-group>

      <accordion-group [panelClass]="customAccordionClass">
        <div class="w-100 d-flex fs-5 py-3" accordion-heading>
          <span class="flex-grow-1">{{
            "payment_failed.reason_title_2" | translate
          }}</span>
          <span class="badge badge-secondary"
            ><fa-icon [icon]="faPlusCircle"></fa-icon
          ></span>
        </div>

        {{ "payment_failed.reason_description_2" | translate }}
      </accordion-group>

      <accordion-group [panelClass]="customAccordionClass">
        <div class="w-100 d-flex fs-5 py-3" accordion-heading>
          <span class="flex-grow-1">{{
            "payment_failed.reason_title_1" | translate
          }}</span>
          <span class="badge badge-secondary"
            ><fa-icon [icon]="faPlusCircle"></fa-icon
          ></span>
        </div>
        {{ "payment_failed.reason_description_3" | translate }}
      </accordion-group>

      <accordion-group [panelClass]="customAccordionClass">
        <div class="w-100 d-flex fs-5 py-3" accordion-heading>
          <span class="flex-grow-1">{{
            "payment_failed.reason_title_4" | translate
          }}</span>
          <span class="badge badge-secondary"
            ><fa-icon [icon]="faPlusCircle"></fa-icon
          ></span>
        </div>
        {{ "payment_failed.reason_description_4" | translate }}
      </accordion-group>

      <accordion-group [panelClass]="customAccordionClass">
        <div class="w-100 d-flex fs-5 py-3" accordion-heading>
          <span class="flex-grow-1">{{
            "payment_failed.reason_title_5" | translate
          }}</span>
          <span class="badge badge-secondary"
            ><fa-icon [icon]="faPlusCircle"></fa-icon
          ></span>
        </div>
        {{ "payment_failed.reason_description_5" | translate }}
      </accordion-group>

      <accordion-group [panelClass]="customAccordionClass">
        <div class="w-100 d-flex fs-5 py-3" accordion-heading>
          <span class="flex-grow-1">{{
            "payment_failed.reason_title_6" | translate
          }}</span>
          <span class="badge badge-secondary"
            ><fa-icon [icon]="faPlusCircle"></fa-icon
          ></span>
        </div>
        {{ "payment_failed.reason_description_6" | translate }}
      </accordion-group>
    </accordion>
  </div>

  <div class="d-grid gap-2 col-10 col-md-4 mx-auto py-4 text-center">
    <button
      type="submit"
      class="a-izi-btn--medium a-izi-btn--medium--primary"
      (click)="tryAgain()"
    >
      {{ "common.try_again" | translate }}
    </button>

    <!-- <button
      type="submit"
      class="a-izi-btn--medium a-izi-btn--medium--outline"
      (click)="goToInvoice()"
    >
      {{ "common.back_to_parc_site" | translate }}
    </button> -->

    <div class="pt-4 pb-1 body--dark">
      {{ "payment_failed.service_provide_by" | translate }}
    </div>
    <a type="submit" class="a-link body--big-m--primary" (click)="goToIzi()">
      {{ iziWebPage }}
    </a>
  </div>
</div>
