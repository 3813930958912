import * as _ from 'lodash';

export default class DataUtil {
  static getValue(data: any): any {
    if (data instanceof Event) {
      return (data.target as HTMLInputElement).value;
    } else if (data instanceof EventTarget) {
      return (data as HTMLInputElement).value;
    } else if (data instanceof Object) {
      return _.get(data, 'value');
    }
    return data;
  }

  static isEmpty(value: any): boolean {
    return !value || value === '' || _.trim(value) === '';
  }
}
