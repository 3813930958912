import { Constants } from '../constants';

export default class StorageUtil {
  static getStoredParams(): any {
    return {
      userId: localStorage.getItem(Constants.LOCAL_STORAGE_USER_ID),
      token: localStorage.getItem(Constants.LOCAL_STORAGE_TOKEN)
    };
  }

  static removeStoredParams(): any {
    localStorage.removeItem(Constants.LOCAL_STORAGE_USER_ID);
    localStorage.removeItem(Constants.LOCAL_STORAGE_TOKEN);
  }
}
