<div class="payment-method pb-3 grid-container">
  <div class="format1">
    <div class="d-flex align-items-center">
      <ng-container *ngFor="let paymentOption of paymentMethodOptions">
        <!-- <input
        class="a-radio"
        style="display: none"
        type="radio"
        name="paymentRadio"
        id="paymentRadio"
        [value]="paymentOption.method"
        [checked]="selectedPaymentMethod === paymentOption.method"
        (change)="selectPaymentMethod($event, $event.target)"
      />
      <label
        for="paymentRadio"
        class="m-fancy-selector--small me-3"
        [ngClass]="{ checked: selectedPaymentMethod === paymentOption.method }"
        (click)="selectPaymentMethod($event, paymentOption.method)"
      > -->
        <input
          class="a-radio"
          style="display: none"
          type="radio"
          name="paymentRadio{{ paymentOption.method }}"
          id="paymentRadio{{ paymentOption.method }}"
          [value]="paymentOption.method"
          [checked]="selectedPaymentMethod === paymentOption.method"
          (change)="selectPaymentMethod($event, $event.target)"
        />
        <label
          for="paymentRadio{{ paymentOption.method }}"
          class="m-fancy-selector--small me-3"
          [ngClass]="{
            checked: selectedPaymentMethod === paymentOption.method
          }"
          (click)="selectPaymentMethod($event, paymentOption.method)"
        >
          <div class="d-flex align-items-center">
            <div class="a-radio"></div>
            <!-- <fa-icon class="px-2" [icon]="paymentOption.icon"></fa-icon>
          <p>{{ paymentOption.method }}</p> -->
            <div class="ms-3" *ngIf="paymentOption.method == 'card'">
              <i class="izi-icon-card m-fancy-selector--small__icon-up"></i>
            </div>
            <div class="ms-3" *ngIf="paymentOption.method == 'qr'">
              <i class="izi-icon-qr-code m-fancy-selector--small__icon-up"></i>
            </div>
            <div
              class="body--darkgrey ms-2"
              [ngClass]="{
                'body-b--darkgrey':
                  selectedPaymentMethod === paymentOption.method
              }"
            >
              {{ paymentOption.label | translate }}
            </div>
          </div>
        </label>
      </ng-container>
    </div>
  </div>
  <div class="format2">
    <div class="custom-radio-button-wrapper-2">
      <ng-container *ngFor="let paymentOption of paymentMethodOptions">
        <div
          class="form-check form-check-inline radio-button-item border-bottom w-100"
          (click)="selectPaymentMethod($event, paymentOption.method)"
        >
          <input
            class="a-radio"
            style="display: none"
            type="radio"
            name="paymentRadioMobile{{ paymentOption.method }}"
            id="paymentRadioMobile{{ paymentOption.method }}"
            [value]="paymentOption.method"
            [checked]="selectedPaymentMethod === paymentOption.method"
            (change)="selectPaymentMethod($event, $event.target)"
          />
          <label
            class="body--darkgrey"
            for="paymentRadioMobile{{ paymentOption.method }}"
          >
            <div class="d-flex align-items-center">
              <div class="a-radio"></div>
              <!-- <fa-icon class="icon__grey" [icon]="paymentOption.icon"></fa-icon> -->
              <div class="ms-3" *ngIf="paymentOption.method == 'card'">
                <i class="izi-icon-card m-fancy-selector--small__icon"></i>
              </div>
              <div class="ms-3" *ngIf="paymentOption.method == 'qr'">
                <i class="izi-icon-qr-code m-fancy-selector--small__icon"></i>
              </div>
              <span
                class="body--darkgrey ms-2"
                [ngClass]="{
                  'body-b--darkgrey':
                    selectedPaymentMethod === paymentOption.method
                }"
              >
                {{ paymentOption.label | translate }}</span
              >
            </div>
          </label>
        </div>
      </ng-container>
    </div>
  </div>
</div>
