import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../services/payment.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Observable } from 'rxjs';
import { NavigatorState, NavigatorStep } from '../../states/navigator.state';
import { parseOrderItems } from '../../core/models/order.model';
import * as _ from 'lodash';
import {
  ADD_DOCUMENT_TYPE,
  UPDATE_INVOICE_FIELD,
  UPDATE_INVOICE_FIELD_IF_EMPTY,
  UPDATE_INVOICE_FIELDS
} from '../../reducers/invoice.reducer';
import { IInvoice, parseInvoiceFields } from '../../core/models/invoice.model';
import { LoaderService } from '../../services/loader.service';
import { UPDATE_ORDER } from '../../reducers/order.reducer';
import { SocketService } from '../../services/socket.service';
import { NavigatorService } from '../../services/navigator.service';
import { NGXLogger } from 'ngx-logger';
import { IUser } from '../../core/models/user.model';
import { take } from 'rxjs/operators';
import { ScriptService } from '../../services/script.service';
import { v4 as uuidv4 } from 'uuid';
import { DocTypeIdentity } from '../../core/models/docTypeIdentity.model';

declare let Cardinal: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  paymentId: string;

  navigator$: Observable<NavigatorState>;
  navigatorStep = NavigatorStep;

  user$: Observable<IUser>;
  invoice$: Observable<IInvoice>;

  errorType: string;

  defaultEmail: string;

  constructor(private route: ActivatedRoute,
              private store: Store<AppState>,
              private paymentService: PaymentService,
              private loader: LoaderService,
              private socketService: SocketService,
              private navigator: NavigatorService,
              private logger: NGXLogger,
              private scriptService: ScriptService
  ) {
    this.navigator$ = this.store.select(state => state.navigator);
    this.user$ = this.store.select(state => state.user);
    this.invoice$ = this.store.select(state => state.invoice);
  }

  async ngOnInit(): Promise<void> {
    this.user$.pipe(take(1)).subscribe((data: IUser) => { this.defaultEmail = data.email; });

    this.paymentId = this.route.snapshot.paramMap.get('id');
    await this.getPaymentData();

    this.socketService.getPaymentSocketNotification().subscribe((data) => {
      let invoice: IInvoice;
      this.invoice$.pipe(take(1)).subscribe((data) => ( invoice = data ));
      let email=_.find(invoice.fields, (field)=>{return field.key==='EMAIL'});
      if (data.uuid === this.paymentId && data.correoElectronico===(email?email.value:'')) {
        if (data.status === 'success') {
          this.loader.close();
          this.navigator.go(NavigatorStep.paymentAccepted);
        } else if (data.status === 'failed') {
          this.loader.close();
          this.navigator.go(NavigatorStep.paymentFailed);
        }
      }
    });
  }

  async getPaymentData(): Promise<any> {
    try {
      const paymentData = await this.paymentService.getPaymentData(this.paymentId);
      this.updateData(paymentData);

      if (_.get(paymentData, 'fechaPago')) {
        this.errorType = 'completed';
        this.navigator.go(NavigatorStep.error);
      } else {
        this.navigator.go(NavigatorStep.invoice);
      }
    } catch (e) {
      this.logger.error(e);
      this.errorType = 'invalid';
      this.navigator.go(NavigatorStep.error);
    }
    this.loader.close();
  }

  updateData(paymentData: any): void {
    let isPrefactura=_.get(paymentData,['config','prefactura'],false);
    const uuid = uuidv4();
    this.store.dispatch({
      type: UPDATE_ORDER,
      payload: {
        items: parseOrderItems(_.get(paymentData, ['factura', 'listaItems'], [])),
        subtotal: _.get(paymentData, ['factura', 'monto']),
        delivery: _.get(paymentData, ['factura', 'delivery']),
        total: _.get(paymentData, ['factura', 'montoTotal']),
        partial: _.get(paymentData, ['factura', 'montoParcial']),
        currency: _.get(paymentData, ['factura', 'moneda']),
        paymentMethods: _.get(paymentData, ['opcionesPago']),
        sessionId: uuid,
        invoice: _.get(paymentData,['factura','uuid'])?true:false,
        notificationUrl: _.get(paymentData,['notificacionUrl']),
        config: _.get(paymentData,['config'])
      }
    });

    if(_.get(paymentData,['contribuyente','isSiat'],false)){
      let types=_.map(_.get(paymentData,['contribuyente','tiposDocumento'],[]),(type)=>{
        return new DocTypeIdentity({
          id: _.get(type,'codigoClasificador',0),
          name:_.get(type,'descripcion','')
        })
      })
      this.store.dispatch({
        type: ADD_DOCUMENT_TYPE,
        payload: {
          documentTypes: types,
          initialValue: isPrefactura?types[0].id:_.get(paymentData,['contribuyente','tipoDocumento','codigoClasificador']) 
        }
      })
    }
    
    this.store.dispatch({
      type: UPDATE_INVOICE_FIELD,
      payload: {
        key: 'NIT',
        field: {
          value: isPrefactura?"0":paymentData.nit,
          readonly: _.get(paymentData,['factura','uuid']) && !_.get(paymentData,['factura','prefactura'])?true:false
        }
      }
    });

    this.store.dispatch({
      type: UPDATE_INVOICE_FIELD,
      payload: {
        key: 'BUSINESS_NAME',
        field: {
          value: isPrefactura?"S/N":paymentData.razonSocial,
          readonly: _.get(paymentData,['factura','uuid']) && !_.get(paymentData,['factura','prefactura'])?true:false
        }
      }
    });

    this.store.dispatch({
      type: UPDATE_INVOICE_FIELD_IF_EMPTY,
      payload: {
        key: 'EMAIL',
        priority: 3,
        field: {
          value: paymentData.correoElectronico,
        }
      }
    });
    if(_.get(paymentData,['config','canSeeCamposExtras'],false)){
      this.store.dispatch({
        type: UPDATE_INVOICE_FIELDS,
        payload: {
          fields: parseInvoiceFields(_.get(paymentData, ['camposExtra']),_.get(paymentData,['factura','camposExtra']),_.get(paymentData,['factura','prefactura'],true))
        }
      });
    }

    this.store.dispatch({
      type: 'UPDATE_INVOICE',
      payload: {
        invoice: {
          nit: paymentData.nit,
          razonSocial: paymentData.razonSocial,
          emailEmpresa: paymentData.correoElectronico,
          email: paymentData.correoElectronico
        }
      }
    });

    this.store.dispatch({
      type: 'UPDATE_PAYER',
      payload: {
        payer: paymentData.pagador
      }
    });

    this.store.dispatch({
      type: 'UPDATE_TAXPAYER',
      payload: {
        taxpayer: {
          id: paymentData.contribuyente.id,
          logo: paymentData.contribuyente.logo,
          logoOrientation: paymentData.contribuyente.logoOrientation,
        }
      }
    });

    this.store.dispatch({
      type: 'UPDATE_DELIVERY_ADDRESS',
      payload: {
        country: paymentData.pagador?.pais || paymentData.pais,
        city: paymentData.pagador?.estado || paymentData.estado,
        address: paymentData.pagador?.direccion,
        save: true
      }
    });
    if(isPrefactura){
      setTimeout(()=>{
        this.navigator.go(NavigatorStep.payment);
      },200)
    }
    // const cybersourceConfig = _.find(_.get(paymentData, ['opcionesPago']), { method: 'CYBERSOURCE' });
    // if(cybersourceConfig){
    //   this.loadScripts({...cybersourceConfig.config, uuid }).then();
    // }
    
    
  }

  async loadScripts(config): Promise<void> {
    try {
      await this.scriptService.load(config);
      Cardinal.configure({
        logging: {
          level: 'verbose'
        }
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
