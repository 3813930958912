import { Injectable } from '@angular/core';
import HttpUtil from '../utils/http.util';
import { Register } from '../core/models/register.model';
import DeviceUtil from '../utils/device.util';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private httpUtil;

  constructor() {
    this.httpUtil = HttpUtil.Instance;
  }

  public register(register: Register): Promise<any> {
    return this.httpUtil.execute({
      url: `/signup`,
      method: 'POST',
      data: {
        nombres: register.firstname,
        apPaterno: register.lastname,
        correoElectronico: register.email,
        contrasena: register.password,
        // confirmarContrasena: register.confirmPassword,
        cadena: DeviceUtil.getUserAgent()
      }
    });
  }

  public resetPassword(register: Register): Promise<any> {
    return this.httpUtil.execute({
      url: `/lost-password/reset`,
      method: 'POST',
      data: {
        correoElectronico: register.email,
        contrasena: register.password,
        codigo: register.code
      }
    });
  }
}
