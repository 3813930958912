import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  faInfoCircle = faInfoCircle;

  @Input()
  type = 'info';

  constructor(private loader: LoaderService) {}

  async ngOnInit(): Promise<void> {
    this.loader.close();
  }
}
