import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { Constants } from '../constants';
import { environment } from '../../environments/environment';

export default class HttpUtil {
  private static instance: HttpUtil;

  private readonly request: AxiosInstance;

  constructor() {
    this.request = axios.create();

    this.initBaseURL();
    this.initRequestInterceptor();
    this.initResponseInterceptor();
  }

  static get Instance(): HttpUtil {
    if (!this.instance) {
      this.instance = new HttpUtil();
    }
    return this.instance;
  }

  private initBaseURL(): void {
    this.request.defaults.baseURL = environment.apiURL;
  }

  private initRequestInterceptor(): void {
    this.request.interceptors.request.use(
      (request: any) => {
        return request;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  private initResponseInterceptor(): void {
    this.request.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        return Promise.reject(error.response);
      }
    );
  }

  async execute(args: any): Promise<any> {
    const { url, method, data, headers, params } = args;
    try {
      const request = await this.request({
        method: method || Constants.REQUEST_METHOD_GET,
        url,
        data,
        headers,
        params,
        timeout: 1000*60*30
      });
      return request.data || request || {};
    } catch (err) {
      if (err.data) {
        if (err.data.errorMessage) {
          throw new Error(err.data.errorMessage);
        } else {
          throw new Error(JSON.stringify(err.data));
        }
      }
      throw err;
    }
  }
}
