import { initialDeliveryState, IDelivery } from '../core/models/delivery.model';

export const UPDATE_DELIVERY_ADDRESS = 'UPDATE_DELIVERY_ADDRESS';
export const UPDATE_DELIVERY_ADDRESS_FIELD = 'UPDATE_DELIVERY_ADDRESS_FIELD';
export const UPDATE_DELIVERY_SCHEDULE = 'UPDATE_DELIVERY_SCHEDULE';
export const UPDATE_DELIVERY_SCHEDULE_FIELD = 'UPDATE_DELIVERY_SCHEDULE_FIELD';

export function deliveryReducer(state: IDelivery = initialDeliveryState, action): any {
  switch (action.type) {
    case UPDATE_DELIVERY_ADDRESS:
      return {
        ...state,
        address: action.payload
      };
    case UPDATE_DELIVERY_ADDRESS_FIELD:
      return {
        ...state,
        address: {
          ...state.address,
          ...action.payload
        }
      };
    case UPDATE_DELIVERY_SCHEDULE:
      return {
        ...state,
        schedule: action.payload
      };
    case UPDATE_DELIVERY_SCHEDULE_FIELD:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
