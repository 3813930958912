import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { NavigatorState, NavigatorStep } from '../states/navigator.state';
import { BACK, GO_TO } from '../reducers/navigator.reducer';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  constructor(private store: Store<AppState>) {}

  go(step: NavigatorStep): void {
    this.store.dispatch({
      type: GO_TO,
      payload: {
        step
      } as NavigatorState
    });
  }

  back(): void {
    this.store.dispatch({
      type: BACK,
    });
  }
}
