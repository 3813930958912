import { initialOrderState, IOrder } from '../core/models/order.model';

export const UPDATE_ORDER = 'UPDATE_ORDER';

export function orderReducer(state: IOrder = initialOrderState, action): any {
  switch (action.type) {
    case UPDATE_ORDER:
      return action.payload;
    default:
      return state;
  }
}
