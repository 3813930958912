import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { NavigatorStep } from '../../states/navigator.state';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { IUser } from '../../core/models/user.model';
import { CityService } from '../../services/city.service';
import { Cities } from '../../core/models/city.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { AgmGeocoder } from '@agm/core';
import GeocoderResult = google.maps.GeocoderResult;
import { Delivery, IDelivery } from '../../core/models/delivery.model';
import { LoaderService } from '../../services/loader.service';
import { take } from 'rxjs/operators';
import { NavigatorService } from '../../services/navigator.service';
import DataUtil from '../../utils/data.util';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit, OnDestroy {

  icon = {
    faMapMarkerAlt
  };

  user$: Observable<IUser>;

  countryList: Array<any>;
  cities: Cities = new Cities();

  map: google.maps.Map;
  mapClickListener: any;

  subscriptions: Array<Subscription> = [];

  initialGeoPosition = {
    lat: -16.2362083,
    long: -68.0469867
  };

  delivery$: Observable<IDelivery>;
  deliveryTmp$: BehaviorSubject<IDelivery> = new BehaviorSubject<IDelivery>(new Delivery());

  constructor(private store: Store<AppState>,
              private cityService: CityService,
              private modalService: NgbModal,
              private geocoder: AgmGeocoder,
              private loader: LoaderService,
              private navigator: NavigatorService) {
    this.user$ = this.store.select(state => state.user);
    this.delivery$ = this.store.select(state => state.delivery);
    this.store.select(state => this.deliveryTmp$.next(state.delivery));
  }

  async ngOnInit(): Promise<void> {
    this.delivery$.pipe(take(1)).subscribe((data) => this.updateDeliveryData(data));
  }

  async updateDeliveryData(data: IDelivery): Promise<void> {
    this.countryList = await this.cityService.getCountryList();
    if (data.address?.country) {
      this.cities.cities = await this.cityService.getCities(data.address.country);
    }
    this.deliveryTmp$.next(data);
  }

  async updateCountry(data: any): Promise<void> {
    this.updateTempAddress({ country: DataUtil.getValue(data) });
    this.cities.cities = await this.cityService.getCities(DataUtil.getValue(data));
  }

  parseSelectorOptions(data: string): Array<any> {
    return data.split(',');
  }

  openSearchMap(modal): void {
    this.loader.open();
    const address = this.deliveryTmp$.getValue().address;
    const city = _.find(this.cities.cities, { id: address.city });

    this.getLocation(address.address, city.nombre, address.country)
      .subscribe((data) => {
        this.updateGeoPosition(data);
        this.open(modal);
      }, (error) => {
        this.getLocation('', city.nombre, city.pais)
          .subscribe((data) => {
            this.updateGeoPosition(data);
            this.open(modal);
          }, () => {
            console.error('data not found. ', error);
          });
      });
  }

  updateGeoPosition(data: any): void {
    this.initialGeoPosition = {
      lat: data.lat(),
      long: data.lng()
    };

    this.updateTempAddress({
      latitude: data.lat(),
      longitude: data.lng()
    });

    this.loader.close();
  }

  getLocation(address: string, city: string, country: string): any {
    return new Observable(observer => {
      this.geocoder.geocode({ address: address + ', ' + city, componentRestrictions: { country } })
        .subscribe((results: GeocoderResult[]) => {
          observer.next(results[0].geometry.location);
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
    });
  }

  public mapReadyHandler(map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener('click', (event: google.maps.MouseEvent) => {
      this.deliveryTmp$.next({
        ...this.deliveryTmp$.getValue(),
        address: {
          ...this.deliveryTmp$.getValue().address,
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng()
        }
      });
    });
  }

  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }

    _.forEach(this.subscriptions, (subscription) => subscription.unsubscribe());
  }

  onMarkerChange(event): void {
    this.updateTempAddress({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng()
    });
  }

  goToDeliverySchedule(): void {
    if (environment.steps.scheduleEnabled) {
      this.navigator.go(NavigatorStep.schedule);
    } else {
      this.navigator.go(NavigatorStep.payment);
    }
  }

  open(content): void {
    this.modalService.open(content, {size: 'lg'}).result.then(() => {
      this.updateSelectedAddress();
    });
  }

  updateSelectedAddress(): void {
    this.store.dispatch({
      type: 'UPDATE_DELIVERY_ADDRESS',
      payload: this.deliveryTmp$.getValue().address
    });

    this.goToDeliverySchedule();
  }

  selectAddress(address): void {
    this.updateTempAddress(address);
    this.updateSelectedAddress();
  }

  getCityName(id: string): string {
    const city = _.find(this.cities.cities, { id });
    return city && city.nombre;
  }

  viewMap(content, address): void {
    this.initialGeoPosition = {
      lat: address.latitude,
      long: address.longitude
    };

    this.updateTempAddress(address);

    this.open(content);
  }

  updateAddressValue(data: any): void {
    this.updateTempAddress({ address: DataUtil.getValue(data) });
  }

  updateCityValue(data: any): void {
    this.updateTempAddress({ city: DataUtil.getValue(data) });
  }

  updateSaveValue(data: any): void {
    this.updateTempAddress({ save: DataUtil.getValue(data) });
  }

  updateDescriptionValue(data: any): void {
    this.updateTempAddress({ description: DataUtil.getValue(data) });
  }

  updateTempAddress(data): void {
    this.deliveryTmp$.next({
      ...this.deliveryTmp$.getValue(),
      address: {
        ...this.deliveryTmp$.getValue().address,
        ...data
      }
    });
  }
}
