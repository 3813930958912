<div class="payment-card pb-3">
  <ng-container
    *ngIf="user$ && (user$ | async).cards && (user$ | async).cards.length > 0"
  >
    <div class="fw-bold fs-5 pb-3">{{ "payment.card.list" | translate }}</div>

    <app-custom-panel [border]="true" [noMargin]="true" [noYPadding]="true">
      <ng-container
        *ngFor="let cardItem of (user$ | async).cards; let i = index"
      >
        <div
          class="py-3 d-flex flex-row align-items-center"
          [ngClass]="{ 'border-bottom': i != (user$ | async).cards.length - 1 }"
          (click)="selectCard(cardItem)"
        >
          <div class="px-3">
            <input
              class="m-input-password__input"
              type="radio"
              name="cardSelector"
              [checked]="card.id === cardItem.id"
              (change)="selectCard(cardItem)"
            />
          </div>
          <div class="flex-3">{{ cardItem.cardNumber }}</div>
          <div class="flex-2">{{ cardItem.name }}</div>
          <div class="flex-2">{{ cardItem.expirationDate }}</div>
          <div class="px-3">
            <button class="btn btn-link" (click)="deleteCard($event, cardItem)">
              <fa-icon [icon]="faTrashAlt"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>

      <button
        *ngIf="card && card.id"
        class="btn btn-link p-3"
        (click)="addNewCard()"
      >
        <fa-icon [icon]="faPlus"></fa-icon>
        {{ "payment.card.add_card" | translate }}
      </button>
    </app-custom-panel>

    <app-custom-panel *ngIf="card && card.id">
      <form #paymentCCVForm="ngForm">
        <div class="fw-bold fs-5 pb-3">
          {{
            "payment.card.ccv_confirmation"
              | translate: { cardNumber: card.cardNumber }
          }}
        </div>

        <div class="">
          <label for="ccv2" class="label--darkgrey--85"
            >{{ "field.ccv.label" | translate }}
            <fa-icon
              class="fa-izi"
              [icon]="faQuestionCircle"
              ngbTooltip="{{ 'field.ccv.tooltip' | translate }}"
              placement="auto"
            ></fa-icon
          ></label>
          <input
            type="number"
            class="m-input-password__input"
            id="ccv2"
            name="ccv2"
            [(ngModel)]="card.ccv"
            placeholder="{{ 'field.ccv.placeholder' | translate }}"
            #cardCCV2="ngModel"
            required
          />
          <div
            *ngIf="
              cardCCV2.invalid &&
              (cardCCV2.dirty || cardCCV2.touched || paymentCCVForm.submitted)
            "
            class="error-message-wrapper fw-bold"
          >
            <ng-container *ngIf="cardCCV2.errors?.required">
              {{ "field.ccv.error.required" | translate }}
            </ng-container>
          </div>
        </div>

        <div class="text-black-50 pt-5 pb-0">
          <fa-icon [icon]="faLock"></fa-icon>
          {{ "payment.ssl" | translate }}
        </div>
      </form>
    </app-custom-panel>
  </ng-container>

  <ng-container
    *ngIf="
      user$ &&
      (user$ | async).cards &&
      (user$ | async).cards.length > 0 &&
      card &&
      !card.id
    "
  >
    <div class="fw-bold fs-5 py-3">{{ "payment.card.new" | translate }}</div>
  </ng-container>

  <app-custom-panel *ngIf="card && !card.id" [noMargin]="true">
    <div class="row col-lg-8 col-md-10 col-sm-12">
      <form #paymentForm="ngForm" class="card-form">
        <!-- <div class="m-button-group-tab pb-3" role="group">
          <input
            type="radio"
            name="typeRadio"
            id="typeDebit"
            autocomplete="off"
            value="'debit'"
            [checked]="selectedCardType === 'debit'"
            (change)="selectCardType($event.target)"
          />
          <label class="btn btn-outline-primary" for="typeDebit"
            >{{ "payment.card.debit" | translate }}{{ selectedCardType }}</label
          >

          <input
            type="radio"
            name="typeRadio"
            id="typeCredit"
            autocomplete="off"
            value="'credit'"
            [checked]="selectedCardType === 'credit'"
            (change)="selectCardType($event.target)"
          />
          <label class="btn btn-outline-primary" for="typeCredit"
            >{{ "payment.card.credit" | translate
            }}{{ selectedCardType }}</label
          >
        </div> -->

        <!-- <div class="m-button-group-tab mb-3" role="group">
          <input
            style="display: none"
            type="radio"
            autocomplete="off"
            name="typeRadio"
            id="typeDebit"
            value="debit"
            [checked]="selectedCardType === 'debit'"
            (change)="selectCardType($event.target)"
          />
          <label
            for="typeDebit"
            class="m-button-group-tab__item m-button-group-tab__item--border-right"
            [ngClass]="{
              checked: selectedCardType === 'debit'
            }"
            >{{ "payment.card.debit" | translate }}</label
          >

          <input
            style="display: none"
            type="radio"
            autocomplete="off"
            name="typeRadio"
            id="typeCredit"
            value="credit"
            [checked]="selectedCardType === 'credit'"
            (change)="selectCardType($event.target)"
          />
          <label
            class="m-button-group-tab__item"
            for="typeCredit"
            [ngClass]="{
              checked: selectedCardType === 'credit'
            }"
            >{{ "payment.card.credit" | translate }}</label
          >
        </div> -->

        <div class="form-group col-12 pb-3">
          <label for="issuedTo" class="label--darkgrey--85">{{
            "field.issued_to.label" | translate
          }}</label>
          <input
            type="text"
            class="a-input-form-group__input"
            id="issuedTo"
            name="issuedTo"
            [(ngModel)]="card.name"
            placeholder="{{ 'field.issued_to.placeholder' | translate }}"
            #cardIssuedName="ngModel"
            required
          />
          <div
            *ngIf="
              cardIssuedName.invalid &&
              (cardIssuedName.dirty ||
                cardIssuedName.touched ||
                paymentForm.submitted)
            "
            class="a-input--error"
          >
            <ng-container *ngIf="cardIssuedName.errors?.required">
              {{ "field.issued_to.error.required" | translate }}
            </ng-container>
          </div>
        </div>

        <div class="form-group col-12 pb-3">
          <label for="cardNumber" class="label--darkgrey--85">{{
            "field.number.label" | translate
          }}</label>
          <input
            type="text"
            class="a-input-form-group__input"
            id="cardNumber"
            name="cardNumber"
            [(ngModel)]="card.cardNumber"
            mask="0000 0000 0000 0000"
            [showMaskTyped]="true"
            placeHolderCharacter="0"
            placeholder="{{ 'field.number.placeholder' | translate }}"
            #cardNumber="ngModel"
            required
          />
          <div
            *ngIf="
              cardNumber.invalid &&
              (cardNumber.dirty || cardNumber.touched || paymentForm.submitted)
            "
            class="a-input--error"
          >
            <ng-container *ngIf="cardNumber.errors?.required">
              {{ "field.number.error.required" | translate }}
            </ng-container>
          </div>
        </div>

        <div class="row pb-3">
          <div class="form-group col-6 col-sm-8">
            <label for="expirationDate" class="label--darkgrey--85">{{
              "field.expiration_date.label" | translate
            }}</label>
            <input
              type="text"
              class="a-input-form-group__input"
              id="expirationDate"
              mask="M0/0000"
              name="expirationDate"
              [(ngModel)]="card.expirationDate"
              [leadZeroDateTime]="true"
              [showMaskTyped]="true"
              placeHolderCharacter="-"
              placeholder="{{
                'field.expiration_date.placeholder' | translate
              }}"
              #cardExpirationDate="ngModel"
              required
            />
            <div
              *ngIf="
                cardExpirationDate.invalid &&
                (cardExpirationDate.dirty ||
                  cardExpirationDate.touched ||
                  paymentForm.submitted)
              "
              class="a-input--error"
            >
              <ng-container *ngIf="cardExpirationDate.errors?.required">
                {{ "field.expiration_date.error.required" | translate }}
              </ng-container>
            </div>
          </div>

          <div class="form-group col-4">
            <label for="ccv" class="label--darkgrey--85"
              >{{ "field.ccv.label" | translate }}
              <fa-icon
                class="fa-izi"
                [icon]="faQuestionCircle"
                ngbTooltip="{{ 'field.ccv.tooltip' | translate }}"
                placement="auto"
                (click)="toggleCCVInstructions()"
              ></fa-icon>
            </label>
            <input
              type="number"
              class="a-input-form-group__input"
              id="ccv"
              name="ccv"
              [(ngModel)]="card.ccv"
              placeholder="{{ 'field.ccv.placeholder' | translate }}"
              #cardCCV="ngModel"
              required
            />
            <div
              *ngIf="
                cardCCV.invalid &&
                (cardCCV.dirty || cardCCV.touched || paymentForm.submitted)
              "
              class="a-input--error"
            >
              <ng-container *ngIf="cardCCV.errors?.required">
                {{ "field.ccv.error.required" | translate }}
              </ng-container>
            </div>
          </div>
        </div>

        <div class="row pb-3">
          <div class="form-group col-md-6 col-sm-12 pb-3">
            <label for="country" class="label--darkgrey--85">{{
              "field.country.label" | translate
            }}</label>
            <select
              class="form-select"
              name="country"
              id="country"
              [(ngModel)]="card.country"
              (change)="updateCountry($event)"
              #cardCountry="ngModel"
              required
            >
              <option value="" class="text-black-50">
                {{ "field.country.placeholder" | translate }}
              </option>
              <option *ngFor="let country of countryList" [value]="country.id">
                {{ country.nombre }}
              </option>
            </select>
            <div
              *ngIf="
                cardCountry.invalid &&
                (cardCountry.dirty ||
                  cardCountry.touched ||
                  paymentForm.submitted)
              "
              class="a-input--error"
            >
              <ng-container *ngIf="cardCountry.errors?.required">
                {{ "field.country.error.required" | translate }}
              </ng-container>
            </div>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="city" class="label--darkgrey--85">{{
              "field.state.label" | translate
            }}</label>
            <select
              class="form-select"
              name="city"
              id="city"
              [(ngModel)]="card.city"
              [disabled]="!cityList || cityList.length === 0"
              #cardCity="ngModel"
              required
            >
              <option value="" class="text-black-50">
                {{ "field.state.placeholder" | translate }}
              </option>
              <option *ngFor="let city of cityList" [value]="city.id">
                {{ city.nombre }}
              </option>
            </select>
            <div
              *ngIf="
                cardCity.invalid &&
                (cardCity.dirty || cardCity.touched || paymentForm.submitted)
              "
              class="a-input--error"
            >
              <ng-container *ngIf="cardCity.errors?.required">
                {{ "field.state.error.required" | translate }}
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group col-12 pb-3">
          <label for="billingAddress" class="label--darkgrey--85">{{
            "field.billing_address.label" | translate
          }}</label>
          <input
            type="text"
            class="a-input-form-group__input"
            id="billingAddress"
            name="billingAddress"
            [(ngModel)]="card.address"
            placeholder="{{ 'field.billing_address.placeholder' | translate }}"
            #cardAddress="ngModel"
            required
          />
          <div
            *ngIf="
              cardAddress.invalid &&
              (cardAddress.dirty ||
                cardAddress.touched ||
                paymentForm.submitted)
            "
            class="a-input--error"
          >
            <ng-container *ngIf="cardAddress.errors?.required">
              {{ "field.billing_address.error.required" | translate }}
            </ng-container>
          </div>
        </div>
        <!-- <div
          *ngIf="user$ && ((user$ | async).id || (user$ | async).uuid)"
          class="form-check"
        >
          <input
            class="form-check-input"
            type="checkbox"
            id="save"
            name="save"
            [checked]="card.save"
            [(ngModel)]="card.save"
          />
          <label class="form-check-label" for="save">
            {{ "payment.card.save_card" | translate }}
          </label>
        </div> -->
      </form>
    </div>

    <div class="d-flex body-small-m--grey pt-3 pb-0">
      <fa-icon [icon]="faLock" class="icon__grey"></fa-icon>
      <span class="ms-1 body--small--grey mt-1">
        {{ "payment.ssl" | translate }}</span
      >
    </div>
  </app-custom-panel>
</div>
