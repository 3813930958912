<div class="app-login container">
  <div class="row align-items-center">
    <div class="col-md-7 col-sm-12">
      <app-custom-panel [defaultWidth]="true">
        <h1 class="title--big--dark p-login__mt-aux-login-password mb-2">
          Crea tu nueva contraseña
        </h1>
        <p class="body--darkgrey mb-4">
          Ingresa el código que enviamos a tu email y una nueva contraseña.
        </p>

        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <label for="email" class="label--darkgrey--85">{{
              "field.email.label" | translate
            }}</label>
            <input
              type="text"
              formControlName="email"
              class="a-input-form-group__input"
              id="email"
              name="email"
              placeholder="{{ 'field.email.placeholder' | translate }}"
              required
            />
            <ng-container *ngIf="f.email.errors && f.email.touched">
              <div *ngIf="f.email.errors.required" class="a-input--error">
                {{ "field.email.error.invalid" | translate }}
              </div>
            </ng-container>
          </div>

          <div class="mb-2">
            <label for="code" class="label--darkgrey--85">{{
              "field.code.label" | translate
            }}</label>
            <input
              type="text"
              formControlName="code"
              class="a-input-form-group__input"
              id="code"
              name="code"
              placeholder="{{ 'field.code.placeholder' | translate }}"
              required
            />
            <ng-container *ngIf="f.code.errors && f.code.touched">
              <div *ngIf="f.code.errors.required" class="a-input--error">
                {{ "field.code.error.required" | translate }}
              </div>
            </ng-container>
          </div>
          <div class="mb-3">
            <a class="a-link body-b--primary" (click)="goToLogin()">
              {{ "common.resend_code" | translate }}
            </a>
          </div>
          <div class="mb-3">
            <label for="password" class="label--darkgrey--85">{{
              "field.password.label" | translate
            }}</label>
            <div class="m-input-password">
              <input
                [type]="showPassword ? 'text' : 'password'"
                class="m-input-password__input"
                formControlName="password"
                id="password"
                name="password"
                placeholder="{{ 'field.password.placeholder' | translate }}"
                required
              />
              <button
                class="m-input-password__icon"
                type="button"
                (click)="toggleShowPassword()"
              >
                <fa-icon
                  class="fa-izi"
                  [icon]="showPassword ? faEyeSlash : faEye"
                ></fa-icon>
              </button>
            </div>
            <ng-container *ngIf="f.password.errors && f.password.touched">
              <div *ngIf="f.password.errors.required" class="a-input--error">
                {{ "field.password.error.invalid" | translate }}
              </div>
            </ng-container>
          </div>

          <div class="mb-3">
            <label for="confirmPassword" class="label--darkgrey--85">{{
              "field.confirm_password.label" | translate
            }}</label>
            <div class="m-input-password">
              <input
                [type]="showConfirmPassword ? 'text' : 'password'"
                class="m-input-password__input"
                formControlName="confirmPassword"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="{{
                  'field.confirm_password.placeholder' | translate
                }}"
                required
              />
              <button
                class="m-input-password__icon"
                type="button"
                (click)="toggleShowConfirmPassword()"
              >
                <fa-icon
                  class="fa-izi"
                  [icon]="showConfirmPassword ? faEyeSlash : faEye"
                ></fa-icon>
              </button>
            </div>
            <ng-container
              *ngIf="f.confirmPassword.errors && f.confirmPassword.touched"
            >
              <div
                *ngIf="f.confirmPassword.errors.required"
                class="a-input--error"
              >
                {{ "field.confirm_password.error.invalid" | translate }}
              </div>
              <div
                *ngIf="f.confirmPassword.errors.mustMatch"
                class="a-input--error"
              >
                {{ "field.confirm_password.error.must_match" | translate }}
              </div>
            </ng-container>
          </div>

          <div class="mt-5">
            <button
              type="submit"
              class="a-izi-btn--medium a-izi-btn--medium--primary"
              [disabled]="!resetPasswordForm.valid"
            >
              {{ "common.reset" | translate }}
            </button>
          </div>
        </form>
      </app-custom-panel>
    </div>
    <div class="col-md-5 col-sm-12">
      <img src="assets/images/reel1.svg" alt="" />
    </div>
  </div>
</div>
